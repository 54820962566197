import React from 'react';
import 'styles/scss/website/pages/blog.scss';
// import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {NavLink, Link} from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import API_CALLS from 'API_CALLS/index';
import CONTENTS from "./CONTENTS";
import Loading from 'global/components/Loading/index';
import CategoryList from './components/CategoryList/index';
import RecentPosts from './components/RecentPosts/index';
//import KeywordsList from './components/KeywordsList/index';
import selfie from 'global/img/selfie.jpg';
const {Container, Row, Column} = window.LayoutBuilder;
const QUERY = API_CALLS.BLOGS.QUERIES.allAndCount();



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      blogCount: 0,
      loading: true,
      loading2: false,
      variables: {
        limit: 6,
        offset: 0,
        sortBy: "duration.startAt",
        dates: [
          {
            "field": "duration.startAt",
            "lte": `${Date.now()}`
          },
          // {
          //   "field": "duration.endAt",
          //   "gt": `${Date.now()}`
          // }
        ],
        manual: [
          {
            path: "language",
            val: window.Session.Language
          }
        ]
      },
      hasNextPage: false,
      hasPrevPage: false,
      totalPages: 1,
      currentPage: 1
    };    
  }

  componentDidMount(){
    this.getRedirectLocation();
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getBlogs);
  }

  componentWillUnmount(){
    this.RunAPIOnceWhenReady.unsubscribe();
  }

  getRedirectLocation = () => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var CATEGORY = urlParams.get('CATEGORY');
    if(CATEGORY) this.onChangeCategory(CATEGORY);
    //console.log("CATEGORY","\n\n",CATEGORY,"\n\n");
    var KEYWORD = urlParams.get('KEYWORD');
    if(KEYWORD) this.onChangKeywords(KEYWORD);
    
  }

  onFailed = () => {
    this.setState({curStatus: "failed", loading: false});
  }

  getMore(){
    this.setState({loading2: true});
    var {variables={}} = this.state;
    variables.offset = Number(variables.offset)+Number(variables.limit);
    this.setState({
      variables
    }, this.getBlogs);
  }


  getBlogs = async () => {
    console.log('getBlogs');
    
    if(!window.Client || !window.Client.query) return false;
    var {variables={}, blogs=[]} = this.state;
    variables.manual.val = window.Session.Language;
    //console.log("variables","\n\n",variables,"\n\n");
    return await window.Client.query({
      query: QUERY,
      variables: variables
    })
    .then(result => {
      //console.log("result");
      //console.log(result,"\n\n");
      var {blogCount=0} = result.data;
      var {hasNextPage, hasPrevPage, totalPages, currentPage} = window.GlobalUtil.paginationCalculator(blogCount, variables.limit, variables.offset);
      this.setState({
        blogs: result.data.blogs, 
        loading: false,
        loading2: false,
        blogCount: result.data.blogCount,
        hasNextPage, 
        hasPrevPage, 
        totalPages,
        currentPage
      });
      return true;
    })
    .catch((error)=>{
      console.log("getBlogs catch", error);
      return false;
    })
  }


  onChangeCategory = (newCategory) => {
    var {variables, category} = this.state;
    if(!newCategory || (category === newCategory)){
      category = "";
      variables.manual = variables.manual.filter(obj=>obj.path!=="category")
    } else {
      category = newCategory;      
      variables.manual.push(
        {
          path: "category",
          val: newCategory
        }
      )
    }

    variables.offset = 0; //THIS WILL RESET US TO THE FIRST PAGE

    this.setState({
      variables: variables,
      category: category
    }, this.getBlogs);
  }


  onChangKeywords = (newKeyword) => {
    var {variables, keywords=[]} = this.state;
    if(keywords.indexOf(newKeyword) > -1){
      keywords.splice(keywords.indexOf(newKeyword), 1)
      variables.manual = variables.manual.filter(obj=>obj.path!=="keywords")
    } else {
      if(!keywords) keywords = [];
      keywords.push(newKeyword)
      variables.manual.push(
        {
          path: "keywords",
          listValues: JSON.stringify(keywords)
        }
      )
    }

    variables.offset = 0; //THIS WILL RESET US TO THE FIRST PAGE


    this.setState({
      variables: variables,
      keywords: keywords
    }, this.getBlogs);
  }


  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    var HEADER = CONTENTS[window.Session.Language].HEADER; //HOPEFULY WE'LL BE ABLE TO PULL ALL THIS INFO FROM THE BLOG POST ITSELF SO EACH ONE CAN HAVE GREAT SEO
    var {blogs=[], blogCount, loading, loading2, hasNextPage, hasPrevPage, totalPages, variables={}, searchValue, category, keywords=[]} = this.state;
    var {limit, offset} = variables;
    return (
      <div className="contentPage" id="PageBlog">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            {/* <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} /> */}
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        {/* <div className="breadcrumbs">
          <Container className="">
            <Row className="">
              <Column className="col1" col="" xs="" sm="" md="" lg="" xl="">
                <div className="breadCrumbTitle">Blog</div>
              </Column>
              <Column className="col2" col="" xs="" sm="" md="" lg="" xl="">
                <div className="breadCrumbsLinks">
                  <ul className="inlineLists">
                    <li className='link'>
                      <a href="/" className="breadCrumbLink">Home</a>
                    </li>
                    <li className="spacer">/</li>
                    <li className='link'>
                      <a href="/blog" className="breadCrumbLink active">Blog</a>
                    </li>
                  </ul>
                </div>
              </Column>
            </Row>
          </Container>
        </div> */}
        <Container className="">
          <Row className="">
            <Column className="" col="12" xs="12" sm="12" md="12" lg="3" xl="3">
              {/*  //ADD SEARCH LATER
              <div id="Search">
                <form 
                  action="" 
                  className="searchForm" 
                  onSubmit={this.onSubmitSearch}>
                  <input 
                      type="file" 
                      accept={accept} 
                      className={`searchFormInput`} 
                      value={searchValue} 
                      name={curValue.name} 
                      onBlur={()=>{
                        this.props.onTouch(curValue.name);
                        if(required) this.Validate(value);
                      }}
                      onChange={e=>{
                        value[index] = e.target.files[0];
                        this.props.onChange(name, value);
                        if(required) this.Validate(e.target.files[0]);
                      }}
                    />
                </form>
              </div> */}
              <CategoryList
                categoryActive={category}
                onChangeCategory={this.onChangeCategory}
              />
              <RecentPosts />
              {/* <KeywordsList
                keywords={keywords}
                onChangKeywords={this.onChangKeywords}
              /> */}
              
              
            </Column>
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT.HeaderText[0]}
              <div className="blogPreview">
                {
                  loading
                  ? <Row className="">
                      <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                        <div className="">
                          <Loading 
                            //floating="true"
                            header={CONTENT.LoadingText[0]}
                            body={CONTENT.LoadingText[1]}
                            inlineStyles={{
                              "LoadingScreen": {
                                "paddingTop": "100px"
                              }
                            }}
                          />
                        </div>
                      </Column>
                    </Row>
                  : <React.Fragment>
                      <Row className="blogPreviewLinks">
                        {
                          (blogs && blogs.length)
                          ? blogs.map((blogObj, index)=>{
                              return (
                                <Blog
                                  key={index}
                                  CONTENT={CONTENT}
                                  index={index%limit}
                                  blogObj={{...blogObj, monkey: "too"}}
                                />
                              )
                            })
                          : <p className="">No Blog Posts Found</p>
                        }
                      </Row>
                      <Row className="">
                        <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                          <div className="">
                            {
                              loading2
                              ? <div className="">
                                  <Loading 
                                    //floating="true"
                                    header={CONTENT.LoadingText[0]}
                                    body={""}
                                    inlineStyles={{
                                      "LoadingScreen": {
                                        //"paddingTop": "100px"
                                      }
                                    }}
                                  />
                                </div>
                              : <div className="buttonGroup center">
                                  <button 
                                    className={`button button2 ${!hasNextPage ? "disabled" : ""}`} 
                                    disabled={!hasNextPage} 
                                    onClick={()=>{
                                    if(hasNextPage) this.getMore();
                                  }}>
                                    {CONTENT.Button1[0]}
                                  </button>
                                </div>
                            }
                          </div>
                        </Column>
                      </Row>

                    </React.Fragment>
                }
              </div>
            </Column>
          </Row>

        </Container>
      </div>
    );
  }
}

function createMarkup(content) {
  return {__html: content};
}

const Blog = (props)=>{
  var {blogObj, index, CONTENT} = props;
  var blogLink = ((window.Session.Language === "ENGLISH") ? "blog" : "elBlog");
  return(
    <Column className="blogPreviewColumn animated fadeIn"  style={{animationDelay: `${.100*index}s`}} col="12" xs="12" sm="12" md="12" lg="6" xl="6">
      <Link className={"nav-link"}  to={`/${blogLink}/${blogObj.url}`}>
        <div className="blogPreview">
        {
          (blogObj && blogObj.mainImage && blogObj.mainImage[0])
          ? <div className="text-center blogPreviewImage"><img src={window.GlobalUtil.getImageUrl({sitefile:blogObj.mainImage[0], boxSize:"600x300"})} alt="" /></div>
          : <div className="text-center blogPreviewImage"><div style={{"width": "100%", "height": "300px", "background": "#ddd"}}></div></div>
        }
        <div className="blogPreviewText">
          <div className="blogCatAndDate">
            <div className="blogPreviewCategories">{props.blogObj.category.toUpperCase()} - </div>
            <div className="blogPreviewDate">{window.GlobalUtil.dateBasic(blogObj.duration.startAt)}</div>
          </div>
          <h4 className="blogPreviewHeader">{window.GlobalUtil.ulify(blogObj.title, 70)}</h4>
          <div className="blogPreviewSummary">{window.GlobalUtil.ulify(blogObj.summary, 150)}</div>
       </div>
      </div>
      </Link>
    </Column>
  )
}










export default Index;
      