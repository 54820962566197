import React from 'react';
import CONTENTS from "./CONTENTS";
import 'styles/scss/global/CandidateQuiz.scss';
import {NavLink, Link} from 'react-router-dom';
import Loading from 'global/components/Loading/index';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import APISaveForTracking, {APISaveForTrackingUnique} from 'API_CALLS/TRACKING/TRACKING_FUNCTIONS';
import CandidateImage1 from 'global/img/candidateQuiz/1.jpg';
import CandidateImage2 from 'global/img/candidateQuiz/2.jpg';
import CandidateImage3 from 'global/img/candidateQuiz/3.jpg';
import CandidateImage4 from 'global/img/candidateQuiz/4.jpg';
import CandidateImage5 from 'global/img/candidateQuiz/5.jpg';
import CandidateImage6 from 'global/img/candidateQuiz/6.jpg';
import CandidateImage7 from 'global/img/candidateQuiz/7.jpg';
import CandidateImage8 from 'global/img/candidateQuiz/8.jpg';
import CandidateImage9 from 'global/img/candidateQuiz/9.jpg';
import CandidateImage10 from 'global/img/candidateQuiz/10.jpg';

import BannerImage1 from 'global/img/candidateQuiz/girlBanner2_1x1.jpg';
import BannerImage2 from 'global/img/candidateQuiz/girlBanner2_4x2.jpg';
import BannerImage3 from 'global/img/candidateQuiz/girlBanner3_3x1.jpg';
import BannerImage4 from 'global/img/candidateQuiz/girlBanner3_4x2.jpg';

// import MouthMovement from 'global/img/mouthMoving/Faster_Mouth_Movement.webp';
// import happyAligners1 from 'global/img/squareGirlAlignerHappy_500.jpg';
// import socialImage from 'global/img/socialImage_500.png';


const {Container, Row, Column} = window.LayoutBuilder;

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageID: "PageCandidate",
      email: "",
      phone: "",
      questions: {},
      step: 1,
      answered: [],
      CandidateQuizOutterStyles: {
        "minHeight": "250px"
      },
      animationClass: ["hidden","","hidden","hidden","hidden","hidden","hidden","hidden","hidden","hidden"]
    };  
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onPressButton=(name, value)=>{
    var {questions={}, step, answered} = this.state;
    questions[name] = value;
    answered[step] = true;
    this.setState({
      questions: questions,
      answered: answered
    }, ()=>this.nextStep(step+1)); 
    this.resultsCleaner();
  }


  resultsCleaner=(name, value)=>{
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    var {questions={}} = this.state;
    var QuestionsResults = ``;
    Object.keys(questions).map((key, index)=>{
      var value = questions[key]
      QuestionsResults +=  `${index} - ${CONTENT.QuestionsNoHTML[Number(key)]} \n`;
      QuestionsResults +=  `${value} \n`;
    })
    //console.log(`QuestionsResults`,"\n\n",QuestionsResults,"\n\n");
  }

  nextStep=()=>{
    if(this.timeoutThing) return;
    var {animationClass, step} = this.state;    
    animationClass[step] = 'hidden';
    animationClass[step+1] = 'active';
    this.setState({animationClass}, ()=>{
      animationClass[step] = 'active';
      animationClass[step+1] = 'hidden';
        this.setState({
          animationClass: animationClass,
          CandidateQuizOutterStyles: {"minHeight": this.CandidateQuizOutterRef.firstChild.clientHeight}
        }, ()=>{
          animationClass[step] = 'animated slideOutLeft';
          animationClass[step+1] = 'animated slideInRight';
          this.setState({
            step: step+1,
            animationClass: animationClass
          }, ()=>{
            this.timeoutThing = setTimeout(()=>{
              animationClass[step] = 'hidden';
              animationClass[step+1] = 'active';
              this.setState({
                animationClass,
                loading: false
              },
                ()=>{
                  clearTimeout(this.timeoutThing)                  
                  this.timeoutThing = undefined
                  if(this.state.step === 8){
                    //window.GlobalUtil.triggerEvent("ShowConfetti", true)
                    window.GlobalUtil.delay(2000).then(()=>{
                      this.nextStep()
                    });
                  }
                  if(this.state.step > 8) window.GlobalUtil.triggerEvent("ShowConfetti", true)
                })
            }, 400)
          })
        })
      })

  }


  backStep=()=>{
    if(this.timeoutThing) return;
    var {animationClass, step} = this.state;
    this.setState({CandidateQuizOutterStyles: {"minHeight": this.CandidateQuizOutterRef.firstChild.clientHeight}})
    if(step === 0) return;

    animationClass[step] = 'hidden';
    animationClass[step-1] = 'active';
    this.setState({animationClass}, ()=>{
      animationClass[step] = 'active';
      animationClass[step-1] = 'hidden';
      this.setState({
        animationClass: animationClass,
        CandidateQuizOutterStyles: {"minHeight": this.CandidateQuizOutterRef.firstChild.clientHeight}
      }, ()=>{
          animationClass[step] = 'animated slideOutRight';
          animationClass[step-1] = 'animated slideInLeft';
          
          this.setState({
            step: step-1,
            animationClass: animationClass
          }, ()=>{
            this.timeoutThing = setTimeout(()=>{
              animationClass[step] = 'hidden';
              animationClass[step-1] = 'active';
              this.setState({animationClass},
                ()=>{
                  clearTimeout(this.timeoutThing)
                  this.timeoutThing = undefined
                })
            }, 400)
          })
        })
      })
  }

  render(){
    var {noDialog} = this.props;
    var {email, phone, questions={}, step, animationClass, isValid, loading, CandidateQuizOutterStyles, answered} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    
    //var {data} = this.props;
    return(
      <div id="CandidateQuiz">
        {
          step < 9
          ? <div id="CandidateQuizSteps" className={`${step<1 ? "hidden" : "" }`}>
              <div className={`progressBar ${step>0 ? "animated fadeIn" : "hidden"}`}>
                <div className="progressBarBars">
                  <div className="bar">
                    <div className={`barColor ${step>1 ? 'active' : ''}`}></div>
                  </div>
                  <div className="bar">
                    <div className={`barColor ${step>2 ? 'active' : ''}`}></div>
                  </div>
                  <div className="bar">
                    <div className={`barColor ${step>3 ? 'active' : ''}`}></div>
                  </div>
                  <div className="bar">
                    <div className={`barColor ${step>4 ? 'active' : ''}`}></div>
                  </div>
                  <div className="bar">
                    <div className={`barColor ${step>5 ? 'active' : ''}`}></div>
                  </div>
                  <div className="bar">
                    <div className={`barColor ${step>6 ? 'active' : ''}`}></div>
                  </div>
                  <div className="bar">
                    <div className={`barColor ${step>7 ? 'active' : ''}`}></div>
                  </div>
                  <div className="subBar"></div>
                </div>
                <div className="progressBarSteps">
                  <div className={`progessStep ${step>0 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 1 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText d-block d-lg-none ${step>1 ? 'active' : ''}`}>{CONTENT.Steps[0]}</div>
                      <div className={`stepText d-none d-lg-block ${step>1 ? 'active' : ''}`}>{CONTENT.Steps[1]}</div>
                    </div>
                  </div>
                  <div className={`progessStep d-none d-lg-block ${step>1 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 2 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText hideOnSmall ${step>2 ? 'active' : ''}`}>{CONTENT.Steps[2]}</div>
                    </div>
                  </div>
                  <div className={`progessStep d-none d-lg-block ${step>2 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 3 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText hideOnSmall  ${step>3 ? 'active' : ''}`}>{CONTENT.Steps[3]}</div>
                    </div>
                  </div>
                  <div className={`progessStep d-none d-lg-block ${step>3 ? 'active' : ''}`}>
                    <div className="stepName ">
                      <i className={`stepIcon fas ${step > 4 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText hideOnSmall ${step>4 ? 'active' : ''}`}>{CONTENT.Steps[4]}</div>
                    </div>
                  </div>
                  <div className={`progessStep d-none d-lg-block ${step>4 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 5 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText hideOnSmall ${step>5 ? 'active' : ''}`}>{CONTENT.Steps[5]}</div>
                    </div>
                  </div>
                  <div className={`progessStep d-none d-lg-block ${step>5 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 6 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText hideOnSmall ${step>6 ? 'active' : ''}`}>{CONTENT.Steps[6]}</div>
                    </div>
                  </div>
                  <div className={`progessStep d-none d-lg-block ${step>6 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 7 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText hideOnSmall ${step>7 ? 'active' : ''}`}>{CONTENT.Steps[7]}</div>
                    </div>
                  </div>
                  <div className={`progessStep ${step>7 ? 'active' : ''}`}>
                    <div className="stepName">
                      <i className={`stepIcon fas ${step > 8 ? "fa-check-square" : "fa-square"}`}/>
                      <div className={`stepText ${step>8 ? 'active' : ''}`}>{CONTENT.Steps[8]}</div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          : null
        }
        
      
        <div id="CandidateQuizOutter" ref={e=>this.CandidateQuizOutterRef = e} style={CandidateQuizOutterStyles}>
          <div id="CandidateQuizWrapper">
            {/* <div className={`question question0 ${animationClass[0]}`}>
              <div className="questionImageWrapper">
                <div className={`buttonImage d-none d-lg-block`}><img src={BannerImage1} alt=""/></div>
                <div className={`buttonImage full d-block d-lg-none`}><img src={BannerImage2} alt=""/></div>
                  <div className="buttonTextZone">
                    <div className="buttonTextWrapper">
                      <div className="questionText">{CONTENT.IntroText[0]}</div>
                      <div className="buttons">
                        <div className="welcomeText basicText">{CONTENT.IntroText[1]}</div>
                        <div className="buttonGroup getStarted">
                          <button className="button1 button" onClick={this.nextStep}>{CONTENT.IntroText[2]}</button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div> */}
            <div className={`question question1 ${animationClass[1]}`}>
              <div className="questionText">{CONTENT.Questions[0]}</div>
              <div className="buttons">
                <div className="buttonGroup inline">
                  <button onClick={()=>this.onPressButton(0, CONTENT.Question1[0])} className={`button2 button ${questions[0] === CONTENT.Question1[0] ? 'active' : ''}`}>{CONTENT.Question1[0]}</button>
                  <button onClick={()=>this.onPressButton(0, CONTENT.Question1[1])} className={`button2 button ${questions[0] === CONTENT.Question1[1] ? 'active' : ''}`}>{CONTENT.Question1[1]}</button>
                </div>
              </div>
            </div>
            <div className={`question question2 ${animationClass[2]}`}>
              <div className="questionText">{CONTENT.Questions[1]}</div>
              <div className="buttons">
                <div className="buttonImages">
                  <div className={`buttonImage ${questions[1] === CONTENT.Question2[0] ? 'active' : ''}`}><img src={CandidateImage1} alt="" onClick={()=>this.onPressButton(1, CONTENT.Question2[0])}/></div>
                  <div className={`buttonImage ${questions[1] === CONTENT.Question2[1] ? 'active' : ''}`}><img src={CandidateImage2} alt="" onClick={()=>this.onPressButton(1, CONTENT.Question2[1])}/></div>
                  <div className={`buttonImage ${questions[1] === CONTENT.Question2[2] ? 'active' : ''}`}><img src={CandidateImage3} alt="" onClick={()=>this.onPressButton(1, CONTENT.Question2[2])}/></div>
                  <div className={`buttonImage ${questions[1] === CONTENT.Question2[3] ? 'active' : ''}`}><img src={CandidateImage10} alt="" onClick={()=>this.onPressButton(1, CONTENT.Question2[3])}/></div>
                </div>
              </div>
            </div>
            <div className={`question question3 ${animationClass[3]}`}>
              <div className="questionText">{CONTENT.Questions[2]}</div>
              <div className="buttons">
                <div className="buttonImages">
                  <div className={`buttonImage ${questions[2] === CONTENT.Question3[0] ? 'active' : ''}`}><img src={CandidateImage4} alt="" onClick={()=>this.onPressButton(2, CONTENT.Question3[0])}/></div>
                  <div className={`buttonImage ${questions[2] === CONTENT.Question3[1] ? 'active' : ''}`}><img src={CandidateImage5} alt="" onClick={()=>this.onPressButton(2, CONTENT.Question3[1])}/></div>
                  <div className={`buttonImage ${questions[2] === CONTENT.Question3[2] ? 'active' : ''}`}><img src={CandidateImage6} alt="" onClick={()=>this.onPressButton(2, CONTENT.Question3[2])}/></div>
                  <div className={`buttonImage ${questions[2] === CONTENT.Question3[3] ? 'active' : ''}`}><img src={CandidateImage10} alt="" onClick={()=>this.onPressButton(2, CONTENT.Question3[3])}/></div>
                </div>
              </div>
            </div>
            <div className={`question question4 ${animationClass[4]}`}>
              <div className="questionText">{CONTENT.Questions[3]}</div>
              <div className="buttons">
                <div className="buttonImages">
                  <div className={`buttonImage ${questions[3] === CONTENT.Question4[0] ? 'active' : ''}`}><img src={CandidateImage7} alt="" onClick={()=>this.onPressButton(3, CONTENT.Question4[0])}/></div>
                  <div className={`buttonImage ${questions[3] === CONTENT.Question4[1] ? 'active' : ''}`}><img src={CandidateImage8} alt="" onClick={()=>this.onPressButton(3, CONTENT.Question4[1])}/></div>
                  <div className={`buttonImage ${questions[3] === CONTENT.Question4[2] ? 'active' : ''}`}><img src={CandidateImage9} alt="" onClick={()=>this.onPressButton(3, CONTENT.Question4[2])}/></div>
                  <div className={`buttonImage ${questions[3] === CONTENT.Question4[3] ? 'active' : ''}`}><img src={CandidateImage10} alt="" onClick={()=>this.onPressButton(3, CONTENT.Question4[3])}/></div>
                </div>
              </div>
            </div>
            <div className={`question question5 ${animationClass[5]}`}>
              <div className="questionText">{CONTENT.Questions[4]}</div>
              <div className="buttons">
                <div className="buttonGroup inline">
                  <button onClick={()=>this.onPressButton(4, CONTENT.Question5[0])} className={`button2 button ${questions[4] === CONTENT.Question5[0] ? 'active' : ''}`}>{CONTENT.Question5[0]}</button>
                  <button onClick={()=>this.onPressButton(4, CONTENT.Question5[1])} className={`button2 button ${questions[4] === CONTENT.Question5[1] ? 'active' : ''}`}>{CONTENT.Question5[1]}</button>
                </div>
              </div>
            </div>
            <div className={`question question6 ${animationClass[6]}`}>
              <div className="questionText">{CONTENT.Questions[5]}</div>
              <div className="buttons">
                <div className="buttonGroup">
                  <button 
                    onClick={()=>this.onPressButton(5, CONTENT.Question6[0])} 
                    className={`button2 button ${questions[5] === CONTENT.Question6[0] ? 'active' : ''}`}>{CONTENT.Question6[0]}</button>
                  <button 
                    onClick={()=>this.onPressButton(5, CONTENT.Question6[1])} 
                    className={`button2 button ${questions[5] === CONTENT.Question6[1] ? 'active' : ''}`}>{CONTENT.Question6[1]}</button>
                  <button 
                    onClick={()=>this.onPressButton(5, CONTENT.Question6[2])} 
                    className={`button2 button ${questions[5] === CONTENT.Question6[2] ? 'active' : ''}`}>{CONTENT.Question6[2]}</button>
                  <button 
                    onClick={()=>this.onPressButton(5, CONTENT.Question6[3])} 
                    className={`button2 button ${questions[5] === CONTENT.Question6[3] ? 'active' : ''}`}>{CONTENT.Question6[3]}</button>
                </div>
              </div>
            </div>
            <div className={`question question7 ${animationClass[7]}`}>
              <div className="questionText">{CONTENT.Questions[6]}</div>
              <div className="buttons">
                <div className="buttonGroup">
                  <button 
                    onClick={()=>this.onPressButton(6, CONTENT.Question7[0])} 
                    className={`button2 button ${questions[6] === CONTENT.Question7[0] ? 'active' : ''}`}>{CONTENT.Question7[0]}</button>
                  <button 
                    onClick={()=>this.onPressButton(6, CONTENT.Question7[1])} 
                    className={`button2 button ${questions[6] === CONTENT.Question7[1] ? 'active' : ''}`}>{CONTENT.Question7[1]}</button>
                  <button 
                    onClick={()=>this.onPressButton(6, CONTENT.Question7[2])} 
                    className={`button2 button ${questions[6] === CONTENT.Question7[2] ? 'active' : ''}`}>{CONTENT.Question7[2]}</button>
                  <button 
                    onClick={()=>this.onPressButton(6, CONTENT.Question7[3])} 
                    className={`button2 button ${questions[6] === CONTENT.Question7[3] ? 'active' : ''}`}>{CONTENT.Question7[3]}</button>
                </div>
              </div>
            </div>
            <div className={`question question8 ${animationClass[8]}`}>
              <Loading 
                  header={"Checking if you're a candidate..."}
                  body={undefined}
                  floating={false}
                  overlay={true}
                  inlineStyles={{
                    LoadingScreen: {
                      "paddingTop": "30px"
                    },
                    loadingIcon: {
                      "maxWidth": "100px",
                      "padding": "20px"
                    },
                    loadingHeader: {
                      "marginBottom": "10px",
                      "fontWeight": "600"
                    }
                  }}
                />
            </div>
            <div className={`question question9 ${animationClass[9]}`}>        
                <div className={`buttonImage d-none d-lg-block`}><img src={BannerImage3} alt=""/></div>
                <div className={`buttonImage full d-block d-lg-none`}><img src={BannerImage4} alt=""/></div>
                <div className="successMessage">{CONTENT.Question8[0]}</div>
                {/* <NavLink className={"button button1"} exact to={"/store/full-treatment-plan"}>Go To Treatment</NavLink> */}
                {
                  step === 9
                  ? <TreatmentPlanBar showCouponInTreatmentPlanBar={true} onClickAddToCart={this.props.onClickAddToCart ? this.props.onClickAddToCart : ()=>{}} API_ref={"TREATMENT_PLAN1"}/>
                  : null
                } 
            </div>
          </div>
        </div>
        <div className={`buttonGroup nav ${(!step || (step>7)) ? "hidden" : "" }`}>
          <button className={`backNavButton button button1 ${step>0 ? "animated fadeIn" : "hidden"} ${step>1 ? "" : "disabled"}`} onClick={this.backStep}><i className="fas fa-chevron-left"/> <span>Back</span></button>
          <button className={`backNavButton button button2 ${step>0 ? "animated fadeIn" : "hidden"} ${answered[step] ? "" : "disabled"}`} onClick={this.nextStep}><span>Next</span> <i className="fas fa-chevron-right"/></button>
        </div>
        
      </div>
    )
  }
}


export default Component;