import React from 'react';
import SignUpFormOrLoginButton from "global/components/SignUpFormOrLoginButton/index";
//import API_CALLS from 'API_CALLS/index';
import ShippingForm from "global/components/ShippingForm/index";
import Loading from "global/components/Loading/index";
import TestCheckoutButton from "global/components/TestCheckoutButton/index";
import {GetBasicUserInfo, UpdateUserInfo, UpdateUserWithAnyGlobalVars} from 'global/utils/helper-functions';
const {Container, Row, Column} = window.LayoutBuilder;
//import CheckoutPage from 'global/components/CheckoutPage/index';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingObject: {},
      donePages: {},
      loading: true,
      activePage: {
        0: "notMoving",
      },
      consent1: false,
      consent2: false
    };    
    this.ClientEventKey = window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{
      //console.log(`LeftSide`,"\n\n","\n\n");
      GetBasicUserInfo({
        noCache: false,
        noGlobalUtilLogin: true, //DON'T USE THE GLOBAL LOGIN UTIL, BECAUSE WE'LL USE IT BELOW
        callBackSuccess: this.onSuccessLogin,
        callBackFailed: ()=>this.setState({loading: false})
      }); 
    });
  }

  componentDidMount(){
    // window.GlobalUtil.State.set("newOrder", {consent: false});
    if(window.Client){
      //console.log(`LeftSide 2`,"\n\n","\n\n");
      GetBasicUserInfo({
        noCache: false,
        noGlobalUtilLogin: true, //DON'T USE THE GLOBAL LOGIN UTIL, BECAUSE WE'LL USE IT BELOW
        callBackSuccess: this.onSuccessLogin,
        callBackFailed: ()=>this.setState({loading: false})
      }); //GET USER IF LOGGED IN AND IF HE ALREADY EXIST IT JUST RETURNS CURRENT SESSION
      this.ClientEventKey.unsubscribe();
    }
  }

  componentWillUnmount(){ 
    //if(this.LoopRef) this.LoopRef.abort();
    this.ClientEventKey.unsubscribe();
    clearTimeout(this.changingPages);
  }


  onUpdateShipping = () => {
    var newPhone = this.state.shippingObject.phone;
    var newShipping = {...this.state.shippingObject};
    delete newShipping.phone;
    
    var newVar = {address: newShipping, id: window.Session.user._id};//JSON.parse(JSON.stringify(currentObject));   
    if(newPhone) newVar.phone = newPhone;

    newVar = window.GlobalUtil.APICleanObj(newVar);
    window.GlobalUtil.consoleLog(`onUpdateShipping newVar`,newVar);
    return UpdateUserInfo(newVar)
      .then((newUserObj)=>{                                   
        if(this) this.setState({date: new Date()})
      })
      .catch(()=>{
        console.log("error updating user in CheckoutPage Left");
      });
    
  }



  onChangePage = (newPageNumber) => {
    if(this.changingPages) return;
    const ActiveClasses = [
      ["slideOutRight moving", "slideInLeft moving"],
      ["slideOutLeft moving", "slideInRight moving"]
    ];
    var {activePage={}} = this.state;
    var oldActivePage = Object.keys(activePage)[0];
    if(Number(oldActivePage) === Number(newPageNumber)) return;
    activePage[oldActivePage] = (Number(oldActivePage) > Number(newPageNumber) ? ActiveClasses[0][0] : ActiveClasses[1][0]);
    activePage[newPageNumber] = (Number(oldActivePage) > Number(newPageNumber) ? ActiveClasses[0][1] : ActiveClasses[1][1]);
    this.setState({
      activePage: activePage
    },()=>{
      this.changingPages = setTimeout(()=>{
        delete activePage[oldActivePage];
        activePage[newPageNumber] = 'notMoving';
        this.setState({
          activePage 
        }, ()=>{
          clearTimeout(this.changingPages);
          this.changingPages = undefined;
        });
      }, 500);
    })
  }


  onSuccessLogin = (login) => {   
    this.setState({loading: false});
    if(!login || !login.token) return;
    var {shippingObject={}, donePages} = this.state;

    window.GlobalUtil.login(login, true)
      .then(async (login)=>{
        //UPDATE USER OBJ WITH ANY GLOBAL VAR THEN RETURN THE CORRECT THING
          var updatedUser = await UpdateUserWithAnyGlobalVars(); 
          if(updatedUser && updatedUser._id){
            login.user = {...updatedUser};
            await window.GlobalUtil.login(login, true); //ONLY UPDATE IF THERE'S ANYTHING TO UPDATE
          }

        if(!shippingObject || !shippingObject.street) shippingObject = {...window.GlobalUtil.deepGetFromString(login, "user,address", {
          street: undefined,
          street2: undefined,
          city: undefined,
          state: undefined,
          country: "USA",
          zipcode: undefined
        })};
        if(!shippingObject.country) shippingObject.country = "USA";
        if(login.user.phone) shippingObject.phone = login.user.phone;
        donePages[0] = "done";
        this.setState({
          donePages,
          shippingObject
        },()=>{
          this.onChangePage(1);
        });
      });
  }


  render(){
    var {curStatus, shippingObject, activePage, donePages, isShippingValid, loading, consent1, consent2} = this.state;      
    var {CONTENT={}, newOrder} = this.props;
    var PaypalButtons = this.props.paypalButtons;      
    if(newOrder) newOrder.consent = true;       
    if(loading) return (
      <div id="CheckoutPageComponentLeftSide">
        <Loading
          header={undefined}
          body={undefined}
          floating={true}
          overlay={true}
          inlineStyles={{
            loadingIcon: {
              "maxWidth": "250px"
            }
          }}
        />
      </div>
    )

    //var {showSideMenu} = this.state;
    return (
      <div id="CheckoutPageComponentLeftSide">
        {
          (!newOrder || !newOrder.products || newOrder.products.length < 1)
          ? <div id="NothingFound">
              <div className="">
                <h2>{CONTENT.SidePayment[0]}</h2>
                <p className="">{CONTENT.SidePayment[1]}</p>
                <div className="buttonGroup">
                  <button className="button button2" onClick={()=>window.Router.history.push(CONTENT.SidePaymentButtons[0].link)}>{CONTENT.SidePaymentButtons[0].text}</button>
                  <button className="button button1" onClick={()=>window.Router.history.push(CONTENT.SidePaymentButtons[1].link)}>{CONTENT.SidePaymentButtons[1].text}</button>
                </div>
              </div>
            </div>
          : <React.Fragment>
              <div id="CheckoutBreadcrumbs">
                <div className="checkoutBreadcrumbSteps">
                  <div className="stepBorder"></div>
                  <div className="steps">
                    <div className={`step ${((activePage[0] !== undefined )||(donePages[0]))? "active" : null} ${donePages[0] ? donePages[0] : ''}`} 
                      onClick={()=>{
                        this.onChangePage(0);
                      }}>
                      <i className="fas fa-circle" />
                      {CONTENT.SidePayment[2]}
                    </div>
                    <div className={`step ${((activePage[1] !== undefined )||(donePages[1]))? "active" : null} ${donePages[1] ? donePages[1] : ''}`} 
                      onClick={()=>{
                        if(donePages[0]) this.onChangePage(1);
                      }}>
                      <i className="fas fa-circle" />
                      {CONTENT.SidePayment[3]}
                    </div>
                    <div className={`step ${activePage[2] !== undefined ? "active" : null}`} 
                      onClick={()=>{
                        if(donePages[1]) this.onChangePage(2);
                      }}>
                      <i className="fas fa-circle" />
                      {CONTENT.SidePayment[4]}
                    </div>
                  </div>
                </div>
              </div>
              <div id="CheckoutSteps">
                {
                  activePage[0] !== undefined && 
                  <StepWrapper id="LoginWrapper" classNames={"stepWrapper animated "+activePage[0]}>
                    <LoginSignup
                      CONTENT={CONTENT}
                      onFail={(fail)=>{

                      }}
                      onSuccess={this.onSuccessLogin}
                    />
                  </StepWrapper>
                }
                {
                  activePage[1] !== undefined && 
                  <StepWrapper id="ShippingWrapper" classNames={"stepWrapper animated "+activePage[1]}>
                    <div className="sectionTitle">{CONTENT.SidePayment[5]}</div>
                    <Shipping
                      currentObject={shippingObject}
                      onChange={(newObj)=>{
                        this.setState({shippingObject: newObj});
                      }}
                      buttonText={CONTENT.SidePayment[6]}
                      onSave={()=>{
                        this.onUpdateShipping()
                        .then(()=>{
                          this.onChangePage(2);
                          donePages[1] = "done";
                          this.setState({donePages});
                        });
                      }}
                      onValidate={(isValid)=>this.setState({isShippingValid: isValid})}
                      isShippingValid={isShippingValid}
                    />
                  </StepWrapper>
                }
                {
                  (activePage[2] !== undefined)  && 
                  <StepWrapper id="CheckoutWrapper" classNames={"stepWrapper animated "+activePage[2]}>
                    <div className="sectionTitle">{CONTENT.SidePayment[7]}</div>
                    <div className="consentMessage" style={{"padding": "15px 0 0 0"}} onClick={()=>{
                        //window.GlobalUtil.State.set("newOrder", {consent: !newOrder.consent})
                        this.setState({"consent1": !consent1})
                      }}>
                      {
                        !consent1 //!newOrder.consent
                        ? <i className="checkBoxIcon fas fa-square" />
                        : <i className="checkBoxIcon fas fa-check-square" />
                      }
                      <div className="consentMessageText">
                        {CONTENT.SidePayment[8]}                                          
                      </div>
                    </div>
                    <div className="consentMessage" onClick={()=>{
                        //window.GlobalUtil.State.set("newOrder", {consent: !newOrder.consent})
                        this.setState({"consent2": !consent2})
                      }}>
                      {
                        !consent2 //!newOrder.consent
                        ? <i className="checkBoxIcon fas fa-square" />
                        : <i className="checkBoxIcon fas fa-check-square" />
                      }
                      <div className="consentMessageText">
                        {CONTENT.SidePayment[9]}                                          
                      </div>
                    </div>
                    {/*<TestCheckoutButton {...this.props}/>*/}
                    {/* <div className={`paypalButtonsWrapper ${!newOrder.consent ? "disabled" : ""}`}> */}
                    <div className={`paypalButtonsWrapper ${(!consent1 || !consent2) ? "disabled" : ""}`}>
                      <PaypalButtons />
                    </div>
                  </StepWrapper>
                }
              </div>
            </React.Fragment>
        }
        
      </div>
    );
  }
}



class StepWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: undefined
    };    

  }

  componentDidMount(){
    this.setState({width: this.StepRef.offsetWidth+"px"})
  }


  render(){
    var {width} = this.state;     
    var {id, classNames} = this.props;  
    var styles = (width ? {"width": width} : {"opacity": "0"});
    if(classNames == "stepWrapper animated notMoving") styles = {};
    return (
      <div id={id} className={(width ? classNames : "")} ref={e=>this.StepRef=e} style={styles}>
        {this.props.children}
      </div>
    );
  }
}


class LoginSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    

  }

  componentDidMount(){
    
  }

  componentWillUnmount(){
  }


  render(){
    var {CONTENT} = this.props;
    var {curStatus} = this.state;    
    var {user} = window.Session;   
    //var {showSideMenu} = this.state;
    return (
      <Row className="">
        {
          user.firstName
          ? <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <div className="text-center">
                <div className="sectionTitle">{CONTENT.LoginSignup[0]} {user.firstName + " " + user.lastName}</div>
                <div className="">{CONTENT.LoginSignup[1]}</div>
                <button className={"button button1 btn-color5 buttonFull"} onClick={()=>{window.GlobalUtil.logout(false)}}>{CONTENT.LoginSignup[2]}</button>
                <br/>
                <br/>
              </div>
            </Column>
          : <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <div className="" style={{}}>
                <div className="sectionTitle" style={{"paddingBottom": "10px"}}>Sign Up</div>
                <div className="">
                  <SignUpFormOrLoginButton 
                    CONTENT={CONTENT.SignUpFormOrLoginButton}
                    id=""
                    className=""
                    //emailOrPasswordWrongMessage={(<p className="">{CONTENT.LoginSignup[2]}</p>)}
                    onFail={this.props.onFail}
                    onSuccess={this.props.onSuccess}
                    buttonText="Login"
                    showSignUpButton={true}
                  />
                </div>
              </div>
            </Column>
        }
      </Row>
    );
  }
}




const Shipping = (props) => {
  var {currentObject, isShippingValid, buttonText} = props;   
  return (
    <Row className="">
      <Column className="">
        <div className="">
          <ShippingForm
            initialValues={currentObject}
            onChange={props.onChange}
            onValidate={props.onValidate}
            phoneNumber={true}
          />
          <button className={"button button1 buttonFull " + (!isShippingValid ? "disabled" : "")} disabled={!isShippingValid} onClick={props.onSave}>{buttonText}</button>
        </div>
      </Column>
    </Row>
  );
}


      


export default Index;
      
