import React from 'react';
import 'styles/scss/website/pages/home.scss';
import {Helmet} from "react-helmet-async";
import HeaderImage from 'website/components/HeaderImage/index';
import TemplatePage from 'website/templates/templatePage/index.js';
import {NavLink} from 'react-router-dom';
//import API_CALLS from 'API_CALLS/index';
//import LogoSquare from 'global/img/logo-dark-square.svg';
import Package1 from 'global/img/happyAligners1.jpg';
//import Package2 from 'global/img/package1.jpg';
import squareAlignerHand from 'global/img/squareAlignerHand.jpg';
//import HIW_Image4 from 'global/img/HIW_Image/HIW_Image4.jpg';
import APISaveForTracking from 'API_CALLS/TRACKING/TRACKING_FUNCTIONS';
// import Banner0 from 'global/img/banner/banner0.jpg';
// import Banner1 from 'global/img/banner/banner1.jpg';
// import Banner2 from 'global/img/banner/banner2.jpg';
// import Banner3 from 'global/img/banner/banner3.jpg';

import Main1 from 'global/img/main_pngs/main1.png';
import Main1_500 from 'global/img/main_pngs/main1_500.png';
import Main1_400 from 'global/img/main_pngs/main1_400.png';
import Main1_250 from 'global/img/main_pngs/main1_250.png';

// import Main3 from 'global/img/main_pngs/main3.png';
// import Main3_500 from 'global/img/main_pngs/main3_500.png';
// import Main3_400 from 'global/img/main_pngs/main3_400.png';
// import Main3_250 from 'global/img/main_pngs/main3_250.png';

//import Step1 from 'global/img/steps/Steps1.jpg';
// import Step1_400 from 'global/img/steps/Steps1_400.jpg';
// import Step1_500 from 'global/img/steps/Steps1_500.jpg';
// import Step1_600 from 'global/img/steps/Steps1_600.jpg';
// import Step1_700 from 'global/img/steps/Steps1_700.jpg';
import Step1_800 from 'global/img/steps/Steps1_800.jpg';

//import Step2 from 'global/img/steps/Steps2.jpg';
// import Step2_400 from 'global/img/steps/Steps2B_400.jpg';
// import Step2_500 from 'global/img/steps/Steps2B_500.jpg';
// import Step2_600 from 'global/img/steps/Steps2B_600.jpg';
// import Step2_700 from 'global/img/steps/Steps2B_700.jpg';
import Step2_800 from 'global/img/steps/Steps2B_800.jpg';

//import Step3 from 'global/img/steps/Steps6.jpg';
// import Step3_400 from 'global/img/steps/Steps6_400.jpg';
// import Step3_500 from 'global/img/steps/Steps6_500.jpg';
// import Step3_600 from 'global/img/steps/Steps6_600.jpg';
// import Step3_700 from 'global/img/steps/Steps6_700.jpg';
import Step3_800 from 'global/img/steps/Steps6_800.jpg';

import MainFooter_500 from 'global/img/squareGirlAligner_500.jpg';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
//import Logo from "global/components/Logo/index";
import CONTENTS from "./CONTENTS";
// import CartButton from 'website/components/CartButton/index';

const {Container, Row, Column} = window.LayoutBuilder;

class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };  
    this.PageAlreadySubmitted = (window.GlobalUtil.LocalStorage.get("setPageAsViewed") ? window.GlobalUtil.LocalStorage.get("setPageAsViewed") : false);         
    // this.ClientEventRef = window.GlobalUtil.subscribeEventOnce("ClientEvent", ()=>{ //BECAUSE ONLY ONCE IT WILL AUTO UNSUBSCRIBE ONCE IT'S RAN
    //   this.APITracking()
    // }); //ROUTER IS READY  
  }

  didMount(){
    //if(window.Client) this.APITracking();
  }

  willUnmount(){
    //this.ClientEventRef.unsubscribe(); //IF THIS IS GETTING REMOVED BEFORE THE EVENT RAN THEN REMOVE IT
  }




  // APITracking = () => {          
  //   //return;
  //   if(this.PageAlreadySubmitted) return;
  //   if(window.Session && window.Session.user && window.Session.user._id) {
  //     window.GlobalUtil.LocalStorage.set("setPageAsViewed", true);
  //     this.PageAlreadySubmitted = true; //IF IS ALREADY EXISTING USER THEN TRACK NOTHING
  //     return; 
  //   } else {
  //     this.PageAlreadySubmitted = true;
  //     APISaveForTracking({page: "HOME",type: "PAGE"})
  //       .then(()=>{
  //         window.GlobalUtil.LocalStorage.set("setPageAsViewed", true);
  //         this.PageAlreadySubmitted = true;
  //         window.handshaketoken = undefined;     
  //       });
  //   }
  // }




  render(){
    //var {showSideMenu} = this.state;
    var phoneString = window.GlobalUtil.getPhoneString(true);          
    var Language = window.Session.Language;
    //Language = (this.props.match.url === "/home") ? "ENGLISH" : "ESPAÑOL";
    //const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language].CONTENT);
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT);  
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageHome">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            {/* <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} /> */}
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="TopMainSection" className="containerSection backgroundSVG4">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="doctorImage bigPicture">
                  <img 
                    src={Main1_500} 
                    alt="Clear Aligner Doctor holding tablet"
                    srcSet={`
                      ${Main1_250} 250w,
                      ${Main1_400} 400w,
                      ${Main1_500} 500w,
                      ${Main1} 800w
                    `}
                    sizes="(max-width: 500px) 250px, (max-width: 767px) 400px, (max-width: 990px) 500px, 100vw"
                  />
                </div>
              </Column>
              <Column className="contentCenter topTextZone" col="12" xs="12" sm="12" md="12" lg="6" xl="8">
                <div className="">
                  {CONTENT.TopMainSection[0]}
                  {/*phoneString && <div className="phone"><strong style={{"paddingRight":"10px"}}>{(Language === "ENGLISH") ? "Call Now!" : "¡Llama Ahora!"}</strong> <a className="phoneNumber" href={`tel:${window.GlobalUtil.getPhoneNumber()}`}><strong>{phoneString}</strong></a></div>*/}
                  <NavLink className={"button button1"} exact to={CONTENT.TopMainSectionButtons[1]}>{CONTENT.TopMainSectionButtons[0]}</NavLink>
                </div>
              </Column>
            </Row>
            <div id="BuyNowProduct">Buy Now</div>
          </Container>
        </div>
        <TreatmentPlanBar/>
        <div id="SloveProblem" className="containerSection backgroundColor1">
          <Container className="">
            <Row className="">
              <Column className="d-flex d-lg-none" col="12" xs="12" sm="12" md="12" lg="6" xl="5">
                <div className="">
                  <div className="">
                    <img src={squareAlignerHand} alt="Hand holding a Clear Aligner."/>
                  </div>
                </div>
                <br/>
                <br/>
              </Column>
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="7">
                <div className="">
                  {CONTENT.SloveProblem[0]}              
                  <div className="buttonGroup">
                    <NavLink className={"button button1 btn-color5"} style={{"margin": "20px 5px 20px"}}  exact to={CONTENT.SloveProblemButtons[1]}>{CONTENT.SloveProblemButtons[0]}</NavLink>
                    <button className={"button button2 btn-color5"} style={{"margin": "20px 5px 20px"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT.SloveProblemButtons[2]}</button>
                  </div>
                </div>
              </Column>
              <Column className="d-none d-lg-flex" col="12" xs="12" sm="12" md="12" lg="6" xl="5">
                <div className="">
                  <div className="">
                    <img src={squareAlignerHand} alt="Hand holding a Clear Aligner."/>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>


        {/*<div id="AboutUs" className="containerSection ">
          <Container className="containerSize2">
            <Row className="">
              <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  <div className="preHeader">PUT THEM AT EASE</div>
                  <h2 className="sectionTitle2">Proin cursus aliquet egestas aliquam lobortis, nisi vel blandit</h2>
                  <div className="subHeader">faucibus, quam erat convallis lorem, sit amet elementum augue diam id sem.</div>
                  <div className="">Nam non nibh quam. Nunc ut eros lorem. Aliquam erat volutpat. Quisque aliquet mauris non dui elementum, sed iaculis lacus lobortis. Integer fringilla lacinia mi et facilisis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>               
                  <br/>         
                </div>
              </Column>
            </Row>
            <Row className="">
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="7">
                <div className="">
                  <img src={Banner0} alt=""/>
                </div>
              </Column>
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="5">
                <div className="">
                  <h3>Aliquam lobortis, nisi vel blandit faucibus, quam erat</h3>
                  <div className="subHeader2">Aliquam lobortis, nisi vel blandit faucibus.</div>
                  <div className="text">Proin cursus aliquet egestas convallis lorem, sit amet elementum augue diam id sem. Nam non nibh quam. Nunc ut eros lorem. Aliquam erat volutpat. Quisque aliquet mauris non dui elementum, sed iaculis lacus lobortis. Integer fringilla lacinia mi et facilisis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>               
                  <div className="buttonGroup">
                    <button className="button button1">blandit faucibus</button>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>*/}

        <div id="SimpleSteps" className="containerSection backgroundColor">
          <Container>
            <Row className="">
              <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  {CONTENT.SimpleSteps[0]}
                  <br/>     
                </div>
              </Column>
            </Row>
            <Row className="stepRow">
              <Column className="contentCenter imageColumn">
                <div className="">
                  <img 
                  className="stepImages" 
                    id="StepImages1"
                    src={Step1_800} 
                    alt="women researching clear aligners on her laptop"
                    // srcSet={`
                    //   ${Step1_400} 400w,
                    //   ${Step1_500} 500w,
                    //   ${Step1_600} 600w,
                    //   ${Step1_700} 700w,
                    //   ${Step1_800} 800w
                    // `}
                    // sizes={`
                    //   (max-width: 500px) 400px,
                    //   (max-width: 767px) 500px, 
                    //   (max-width: 991px) 400px, 
                    //   (max-width: 1199px) 600px, 
                    //   (max-width: 1399px) 700px, 
                    //   100vw
                    // `}
                  />
                  <br/>
                  <br/>
                </div>
              </Column>
              <Column className="contentCenter textZone" >
                <div className="">
                  {CONTENT.SimpleSteps[1]}              
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>
            <Row className="stepRow">
              <Column className="contentCenter imageColumn d-flex-800">
                <div className="">
                  <img 
                  className="stepImages" 
                    id="StepImages2"
                    src={Step2_800} 
                    alt="profesionals creating clear aligners programs"
                    // srcSet={`
                    //   ${Step2_400} 400w,
                    //   ${Step2_500} 500w,
                    //   ${Step2_600} 600w,
                    //   ${Step2_700} 700w,
                    //   ${Step2_800} 800w
                    // `}
                    // sizes={`
                    //   (max-width: 500px) 400px,
                    //   (max-width: 767px) 500px, 
                    //   (max-width: 991px) 400px, 
                    //   (max-width: 1199px) 600px, 
                    //   (max-width: 1399px) 700px, 
                    //   100vw
                    // `}
                  />
                  <br/>
                  <br/>
                </div>
              </Column>
              <Column className="contentCenter textZone textZoneLeft">
                <div className="">
                  {CONTENT.SimpleSteps[2]}
                </div>
              </Column>
              <Column className="contentCenter imageColumn d-none-800">
                <div className="">
                  <img 
                  className="stepImages" 
                    id="StepImages2B"
                    src={Step2_800} 
                    alt="profesionals creating clear aligners programsr"
                    // srcSet={`
                    //   ${Step2_400} 400w,
                    //   ${Step2_500} 500w,
                    //   ${Step2_600} 600w,
                    //   ${Step2_700} 700w,
                    //   ${Step2_800} 800w
                    // `}
                    // sizes={`
                    //   (max-width: 500px) 400px,
                    //   (max-width: 767px) 500px, 
                    //   (max-width: 991px) 400px, 
                    //   (max-width: 1199px) 600px, 
                    //   (max-width: 1399px) 700px, 
                    //   100vw
                    // `}
                  />
                  <br/>
                  <br/>
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>
            <Row className="stepRow">
              <Column className="contentCenter imageColumn">
                <div className="">
                  <img className="stepImages" 
                    id="StepImages3"
                    src={Step3_800} 
                    alt="women tracking her clear aligners progress"
                    // srcSet={`
                    //   ${Step3_400} 400w,
                    //   ${Step3_500} 500w,
                    //   ${Step3_600} 600w,
                    //   ${Step3_700} 700w,
                    //   ${Step3_800} 800w
                    // `}
                    // sizes={`
                    //   (max-width: 500px) 400px,
                    //   (max-width: 767px) 500px, 
                    //   (max-width: 991px) 400px, 
                    //   (max-width: 1199px) 600px, 
                    //   (max-width: 1399px) 700px, 
                    //   100vw
                    // `}
                  />
                  <br/>
                  <br/>
                </div>
              </Column>
              <Column className="contentCenter textZone">
                <div className="">
                  {CONTENT.SimpleSteps[3]}
                </div>
              </Column>
            </Row>
            <br/>
            <br/> 
            <Row className="text-center">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  {CONTENT.SimpleSteps[4]}
                  <div className="buttonGroup center">
                    <NavLink className={"button button2 btn-color5"} exact to={CONTENT.SimpleStepsButtons[1]}>{CONTENT.SimpleStepsButtons[0]}</NavLink>
                    <NavLink className={"button button1"} exact to={CONTENT.SimpleStepsButtons[3]}>{CONTENT.SimpleStepsButtons[2]}</NavLink>
                    {/* <button className={"button button2"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT[23]}</button> */}
                  </div>           
                </div>
              </Column>
            </Row>
          </Container>
        </div>



        {/*
        <div id="Testimonials" className="containerSection backgroundImage1">
          <div className="">
            <Container className="" fluid="">
              <Row className="">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="testimonialsBlogContentWrapper">
                    {CONTENT.Testimonials[0]}
                    <div className="buttonGroup center">
                      <NavLink className={"button button1"} exact to={CONTENT.TestimonialsButtons[1]>{CONTENT.TestimonialsButtons[0]}</NavLink>
                    </div>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>
        </div>
        */}


      


        <div id="PricingPlans" className="containerSection">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="text-center">
                  {CONTENT.PricingPlans[0]}
                  <br/>
                </div>
              </Column>
            </Row>
            <Row className="">
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <div className="">
                  <div className="">
                    <img src={Package1} alt="Young lady holding clear aligners, model teeth, and smiling."/>
                    <br/>
                    <br/>
                  </div>
                </div>
              </Column>
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <div className="">
                  {CONTENT.PricingPlans[1]}
                  <div className="buttonGroup">
                    <NavLink className={"button button2 btn-color5"} exact to={CONTENT.PricingPlansButtons[1]}>{CONTENT.PricingPlansButtons[0]}</NavLink>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>


         
        

        


        <div id="HaveQuestions" className="containerSection backgroundImage3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="text-center">
                  {/*<img 
                    src={Main3_500} 
                    alt="women with invisible aligner"
                    srcSet={`
                      ${Main3_250} 250w,
                      ${Main3_400} 400w,
                      ${Main3_500} 500w,
                      ${Main3} 800w
                    `}
                    sizes="(max-width: 500px) 250px, (max-width: 767px) 400px, (max-width: 990px) 400px, 100vw"
                  />*/}
                  <HeaderImage
                    img={MainFooter_500}
                    alt="women with invisible aligner"
                    right={true}
                  />
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                <div className="">
                  {CONTENT.HaveQuestions[0]}
                  <div className="subHeader">
                    {CONTENT.HaveQuestions[1]} 
                    {(phoneString) && <span>{(Language === "ENGLISH") ? "Or Call" : "O Llama"} <a className="phoneNumber" href={`tel:${window.GlobalUtil.getPhoneNumber()}`}><strong>{phoneString}</strong></a></span>}
                  </div>
                  <div className="buttonGroup">
                    <NavLink className={"button button1 btn-color5"} exact to={CONTENT.HaveQuestionsButtons[1]}>{CONTENT.HaveQuestionsButtons[0]}</NavLink>
                    <button className={"button button2 btn-color5"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT.HaveQuestionsButtons[2]}</button>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>

      </div>
    );
  }
}

export default Index;
      
