import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/contact.scss';
import USER_PAGES from 'user/normal/pages/pages.json';
import USER_LAB_PAGES from 'user/lab/pages/pages.json';
import USER_DOC_PAGES from 'user/doctor/pages/pages.json';
import ADMIN_PAGES from 'admin/pages/index';
//import PROMOTIONS_PAGES from 'promotions/pages/pages.js';


import TemplatePage from 'website/templates/templatePage/index.js';
import ContactForm from 'global/components/ContactForm/index';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {...this.state,
  //   //};    
  // }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //var {showSideMenu} = this.state;   
    const AdminPages = ADMIN_PAGES();      
    //console.log(`AdminPages`,"\n\n",AdminPages,"\n\n");
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    var phoneString = window.GlobalUtil.getPhoneString(true);
    return (
      <div className="contentPage" id="PageContact" className="backgroundSVG1">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          <Row className="title">
            <Column className="" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
              <div className="">
                {CONTENT.Section2[0]}
                <ContactForm noDialog={true} showButton={true} hideLogo={true}/>
                <br/>
              <br/>
              </div>
            </Column>
            <Column className="" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
              <div className="">
                {CONTENT.Section1[0]}
              </div>
              <br/>
              {CONTENT.Section1[1]}
              <div className="infoItems">
                <div className="infoItem">
                  {CONTENT.Section1[2]}
                  <div className="infoContent">
                    {window.SiteSettings.companyName}
                  </div>
                </div>
                <div className="infoItem">
                {CONTENT.Section1[3]}
                <div className="infoContent">
                  {window.SiteSettings.publicEmailAddress}
                </div>
                </div>
                <div className="infoItem">
                {CONTENT.Section1[4]}
                <div className="infoContent">
                  <a className="phoneNumber" href={`tel:${window.GlobalUtil.getPhoneNumber()}`}>{phoneString}</a>
                </div>
                </div>
                <div className="infoItem">
                {CONTENT.Section1[5]}
                <div className="infoContent">
                  {window.GlobalUtil.formatAddress(window.SiteSettings.address)}
                </div>
                </div>
                <div className="infoItem">
                {CONTENT.Section1[6]}
                <div className="infoContent">
                  {CONTENT.Section1[7]}
                </div>
                </div>
              </div>
              <br/>
              {CONTENT.Section1[8]}
              <br/>
              <br/>
            </Column>
            
          </Row>
        </Container>
        {
          (navigator.userAgent === 'ReactSnap') && //IF REACT SNAP IS RUNNING THEN LOAD ALL USER PAGES
          <div className="" style={{"display": "none"}}>
            <a href="/">Home</a>
            <a href="/checkout">checkout</a>
            {/* 
              <a href="/buykit">buykit</a>
              <a href="/promoReport">promoReport</a>
              <a href="/promoReports">promoReports</a>
              <a href="/benefitsreports">benefitsreports</a>
              <a href="/escuelas">Escuelas</a>
              <a href="/dentalselect">dentalselect</a>
              <a href="/schools">Schools</a>
            */}
            {
              USER_PAGES[window.Session.Language].map((page, index)=>{
                return(
                  <a key={index} href={page.Paths[0]}>{page.Name}</a>
                )
              })
            }
            {
              USER_LAB_PAGES.map((page, index)=>{
                return(
                  <a key={index} href={page.Paths[0]}>{page.Name}</a>
                )
              })
            }
            {
              USER_DOC_PAGES.map((page, index)=>{
                return(
                  <a key={index} href={page.Paths[0]}>{page.Name}</a>
                )
              })
            }
            {
              AdminPages && 
              Links(AdminPages)
            }
            {/*
              PROMOTIONS_PAGES.map((page, index)=>{
                return(
                  <a key={index} href={'/promotion'+page.Paths[0]}>{page.Name}</a>
                )
              })
            */}
          </div>
        }
      </div>
    );
  }
}


const Links = (LinkArray)=>{
  return(
    LinkArray.map((page, index)=>{
      return(
        <React.Fragment key={index} >
          <a href={page.Paths[0]}>{page.Name}</a>
          {
            page.SubPaths && 
            Links(page.SubPaths)
          }
        </React.Fragment>
      )
    })
  )
}

export default Index;

      
