import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';
import EmailSender from 'admin/components/EmailSender/index';
import TodoList from 'global/components/TodoList/index';
import NewPatient from './newPatient';
import NewUser from './newUser';
import NewDoctor from './newDoctor';
import NewAdmin from './newAdmin';
const {Container, Row, Column} = window.LayoutBuilder;
const MUTATION_UPDATE_SITE_SETTINGS = API_CALLS.SITE_SETTINGS.MUTATIONS.update();
// const QUERY_COM = API_CALLS.COMS.QUERIES.count();
// const QUERY_USERS = API_CALLS.USERS.QUERIES.count();
// const QUERY_ORDERS = API_CALLS.ORDERS.QUERIES.count();
// const QUERY_BLOGS = API_CALLS.BLOGS.QUERIES.count();
// const QUERY_PRODUCTS = API_CALLS.PRODUCTS.QUERIES.count();




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideMenu: false, 
      activeColumn: ""
    };    
    // this.APIMutationCalledKey = window.GlobalUtil.subscribeEvent("APIMutationCalled", whatMutated=>{ //SHOULD RUN EACH TIME MUTATION HAPPENS AND LETS ME DECIDED WHAT TO UPDATE
    //   //whatMutated
    //   //FOR NOW ON EACH MUTATION WE WILL GET ALL STUFF AGAIN
    //   this.getNumberOfStuffTodo();
    //   window.GlobalUtil.ForceUpdateDB();
    //   this.getAPIDashboard();
    // });
  }

  componentDidMount(){
    this.getAPIDashboard();
    this.getAPIDashboardTrackingPages();
  }

  componentWillUnmount(){
    //this.APIMutationCalledKey.unsubscribe();
  }

  getAPIDashboardTrackingPages = () => {
    window.Client.query({
      query: API_CALLS.REPORTS.QUERIES.reports_pages(),
      fetchPolicy: "no-cache"
    })
    .then(result => {
      // console.log("SUCCESS GET SETTINGS");
      //console.log('result.data', result.data);
      
      var pageTrackingObj = result.data.reports_pages[0];
      // console.log("pageTrackingObj","\n\n",pageTrackingObj,"\n\n");    
      // var report = (pageTrackingObj.report ? JSON.parse(pageTrackingObj.report) : null);
      // console.log('pageTrackingObj report', report);
      if(this) this.setState({pageTrackingObj})
    })
    .catch((error)=>{
      console.log("catch", error);
    })
  }


  getAPIDashboard = () => {
    window.Client.query({
      query: API_CALLS.REPORTS.QUERIES.reports_count_recent(),
      fetchPolicy: "no-cache"
    })
    .then(result => {
      // console.log("SUCCESS GET SETTINGS");
      //console.log('result.data', result.data);
      
      var dashboardObj = result.data.reports_count_recent[0];
      //console.log("dashboardObj","\n\n",dashboardObj,"\n\n");    
      if(this) this.setState({dashboardObj})
    })
    .catch((error)=>{
      console.log("catch", error);
    })
  }

  onChangeActiveColumn = (activeColumn) => {
    this.setState({activeColumn: activeColumn});
  }



  render(){
    var {
      showSideMenu, 
      pageTrackingObj={},
      dashboardObj={},
      activeColumn
    } = this.state;
    var {data} = this.props;
    if(!window.SiteSettings.notes || !Array.isArray(window.SiteSettings.notes)) window.SiteSettings.notes = [];
    //console.log("this.props");
    //console.log(this.props,"\n\n");
          
    return (
      <div id="Dashboard">
        {/*<EmailSender
          curObj={{
            whatEmail: "RESET_PASSWORD",
            emailData: {
              whoToEmail: "danielezaldivar@gmail.com",
              whoToCC: "",
              language: "ENGLISH",
              UID: "6137a23385163d43ead02b24",
              OID: "",
              TPID: "",
            }
          }}
        />*/}
        <Container className="">
          {/*<Row className="">
            <Column className="" col="12" xs="12" sm="12" md="" lg="" xl="">
              <div className="">
                <h1>Dashboard</h1>
              </div>
            </Column>
          </Row>*/}

          <Row className="">
            <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="9">
              <Row>
                <Column>
                  <div className="section">
                    <div className="title">
                      Page Tracking
                    </div>
                    <div className="card">
                      <div className="cardContent">
                        <DeepPageTracking
                          // HeaderTitles={[
                          //   <span className="categoryIcon">
                          //     <span className="category">Today</span>
                          //     <i className="fas fa-calendar-day" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">This Week</span>
                          //     <i className="fas fa-calendar-week" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">1 Week Ago</span>
                          //     <i className="fas fa-calendar-week" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">2 Weeks Ago</span>
                          //     <i className="fas fa-calendar-week" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">3 Weeks Ago</span>
                          //     <i className="fas fa-calendar-week" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">This Month</span>
                          //     <i className="fas fa-calendar-alt" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">Last Month</span>
                          //     <i className="fas fa-calendar" /> 
                          //   </span>,
                          //   <span className="categoryIcon">
                          //     <span className="category">Total</span>
                          //     <i className="fas fa-calculator" /> 
                          //   </span>
                          // ]}
                          activeColumn={activeColumn}
                          onChangeActiveColumn={this.onChangeActiveColumn}
                          trackingObj={pageTrackingObj}
                        />
                      </div>
                    </div>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column>
                  <div className="section">
                    <div className="title">
                      System Tracking
                    </div>
                    <div className="card">
                      <div className="cardContent">
                        <TrackingForm
                          HeaderTitles={[
                            <span className="categoryIcon">
                              <span className="category">Today</span>
                              <i className="fas fa-calendar-day" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">This Week</span>
                              <i className="fas fa-calendar-week" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">1 Week Ago</span>
                              <i className="fas fa-calendar-week" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">2 Weeks Ago</span>
                              <i className="fas fa-calendar-week" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">3 Weeks Ago</span>
                              <i className="fas fa-calendar-week" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">This Month</span>
                              <i className="fas fa-calendar-alt" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">Last Month</span>
                              <i className="fas fa-calendar" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">Total</span>
                              <i className="fas fa-calculator" /> 
                            </span>
                          ]}
                          activeColumn={activeColumn}
                          onChangeActiveColumn={this.onChangeActiveColumn}
                          dashboardObj={dashboardObj}
                        />
                      </div>
                    </div>
                  </div>
                </Column>
              </Row>
              {/*
              <Row>
                <Column>
                  <div className="section">
                    <div className="title">
                      Money Tracking Sold
                    </div>
                    <div className="card">
                      <div className="cardContent">
                        <TrackingForm
                          HeaderTitles={[
                            <span className="categoryIcon">
                              <span className="category">Today</span>
                              <i className="fas fa-calendar-day" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">This Week</span>
                              <i className="fas fa-calendar-week" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">This Month</span>
                              <i className="fas fa-calendar-alt" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">Last Month</span>
                              <i className="fas fa-calendar" /> 
                            </span>,
                            <span className="categoryIcon">
                              <span className="category">Total</span>
                              <i className="fas fa-calculator" /> 
                            </span>
                          ]}
                          dashboardObj={MONEY_TRACKING_REPORT_DEMO}
                        />
                      </div>
                    </div>
                  </div>
                </Column>
              </Row>
                Object.keys(dashboardObj).map((key, index)=>{
                  var CurObj = dashboardObj[key];
                  return(
                    <div className="section" key={index}>
                      <div className="title">
                        {key}
                      </div>
                      <div className="card">
                        <div className="cardContent">
                          <Row className="">
                            {
                              Object.keys(CurObj).map((key, index)=>{
                                var CurObjDate = CurObj[key];
                                return(
                                  <Column key={index} className="" col="" xs="" sm="" md="" lg="" xl="">
                                    <div className="progressCardWithIcon">
                                      <div className="progressCardIcon">
                                        {
                                          (key === "Today") &&
                                          <div className="circleBox backgroundColor1">
                                            <i className="fas fa-calendar-day"></i>
                                          </div>
                                        }
                                        {
                                          (key === "This Week") &&
                                          <div className="circleBox backgroundColor4">
                                            <i className="fas fa-calendar-week"></i>
                                          </div>
                                        }
                                        {
                                          (key === "This Month") &&
                                          <div className="circleBox backgroundColor5">
                                            <i className="fas fa-calendar-alt"></i>
                                          </div>
                                        }
                                        {
                                          (key === "Last Month") &&
                                          <div className="circleBox backgroundColor3">
                                            <i className="fas fa-calendar"></i>
                                          </div>
                                        }
                                      </div>
                                      <div className="subCardProgress">
                                        <div className="title">{key}</div>
                                        <div className="subCardContent">
                                          <div className={`numberWrapper border-color${index+1}`}>{CurObjDate}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </Column>
                                )
                              })
                            }
                          </Row>
                        </div>
                      </div>
                    </div>
                  )
                })
              */}
            </Column>
            <Column className="fullHeight" col="12" xs="12" sm="12" md="12" lg="12" xl="3">
              <div className="section">
                <div className="title">
                  Quick Links
                </div>
                {/*<button 
                  style={{"maxWidth":"250px","width":"100%","fontWeight":"700","textTransform":"uppercase"}} 
                  className="button button1" 
                  onClick={()=>{
                    window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                      NAME: "USERS",
                      TITLE: "User",
                      TYPE: "NEW"
                    });
                  }}>
                    <span>New User</span>&nbsp;&nbsp;<i className="fas fa-user-plus" />
                </button>*/}
                <div className="newUserButtons">
                  <NewUser />
                  <NewPatient />
                  <NewDoctor />
                  {/*<NewAdmin />*/}
                </div>
                {/*<button 
                  style={{"maxWidth":"250px","width":"100%","fontWeight":"700","textTransform":"uppercase"}} 
                  className="button button1 btn-color2">
                    <i className="fas fa-comments"></i>&nbsp;&nbsp;<span>New Com</span>
                </button>*/}
              </div>

              <div className="section">
                <div className="title">
                  Checklist
                </div>
                <div className="card">
                  <div className="cardContent" style={{"margin":"-20px"}}>
                    <TodoList 
                      notesList={window.SiteSettings.notesList}
                      onUpdateAPI={this.props.onUpdateSiteSettings}
                    /> 
                  </div>
                </div>
              </div>
            </Column>
          </Row>





          
          <br/>
          {/*<Row className="">
            <Column className="" col="12" xs="12" sm="12" md="7" lg="7" xl="7">
              <div className="section">
                <div className="title">
                  Slow Burner Scheduled
                </div>
                <div className="card">
                  <div className="cardContent">
                    {
                      CONTACT_SCHEDULE.map((person, index)=>{
                        return(
                          <ContactSchedule
                            key={index}
                            person={person}
                          />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Column>
            <Column className="" col="12" xs="12" sm="12" md="5" lg="5" xl="5">
              <div className="section">
                <div className="title">
                  Growth
                </div>
                <div className="card">
                  <div className="cardContent">
                    <div className="">
                      SOME CHART SHOWING GROWTH HERE
                    </div>
                  </div>
                </div>
              </div>
            </Column>
          </Row>*/}
        </Container>
      </div>
    );
  }
}


const DeepPageTracking = (props) => {
  var {trackingObj, activeColumn, onChangeActiveColumn} = props;
  var report = (trackingObj.report ? JSON.parse(trackingObj.report) : null);
  //console.log('report', report);
  if(!report || !report.length) return <div id="DeepPageTracking"></div>;
  
  return(
    <div id="DeepPageTracking">
      {
        report.map((obj, index)=>{
          return(
            <div className="" key={index} >
              <div className="pageHeader">
                <div className="pageName">
                  <strong>{obj.page}</strong>
                </div>
                <div className="pageURL">
                  <strong>URL: </strong>{obj.url}
                </div>  
              </div> 
              <div className="tableSection">
                <DeepPageTrackingForm 
                  id={trackingObj._id.slice(6)+index}
                  reportArray={obj.report}
                  activeColumn={activeColumn}
                  onChangeActiveColumn={onChangeActiveColumn}
                />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}




const DeepPageTrackingForm = (props) => {
  var {id, reportArray, activeColumn, onChangeActiveColumn} = props;
  if((!reportArray || !reportArray.length)) return <table className={`table reportTable`} ></table>;
  return(
    <table className={`table reportTable`} >
      <thead className="tableHeaders thead-dark">
        <tr>

          <th className={`tableHeader tableHeaderOther`}>WATCHING</th>
          {
            reportArray[0].report.map((reportObj, index)=>{
              var tableColClass = `tableColTF${index+2}${id}`;
              var isActive = (activeColumn === tableColClass) ? true : false;
              return(
                <th className={`tableHeader tableHeaderOther ${tableColClass} ${isActive ? "activeCol" : ""}`} key={index} onMouseEnter={()=>onChangeActiveColumn(tableColClass)} onMouseLeave={()=>onChangeActiveColumn("")}>
                  {reportObj.name}
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody className="tableBody">
        {
          (reportArray && reportArray.length) && 
          reportArray.map((reportObj, index2)=>{
            return(
              <tr key={index2}>
                <th className={`tableValues tableValuesNum tableValuesOthers`} width="80">
                  <div className="tableValue category">
                    <strong>{reportObj.title}</strong>
                  </div>
                </th>
                {
                  reportObj.report.map((reportObjInner, index)=>{
                    var tableColClass = `tableColTF${index+2}${id}`;
                    var isActive = (activeColumn === tableColClass) ? true : false;
                    return(
                      <th key={index} className={`tableValues tableValuesNum tableValuesOthers ${tableColClass} ${isActive ? "activeCol" : ""}`} onMouseEnter={()=>onChangeActiveColumn(tableColClass)} onMouseLeave={()=>onChangeActiveColumn("")}>
                        <div className="tableValue">
                          {reportObjInner.value}
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}



const TrackingForm = (props) => {
  var {dashboardObj, HeaderTitles, activeColumn, onChangeActiveColumn} = props;
  var report = (dashboardObj.report ? JSON.parse(dashboardObj.report) : null);
  //console.log('report', report);
  if(!report) return <table className={`table reportTable`} ></table>;
  return(
    <table className={`table reportTable`} >
      <thead className="tableHeaders thead-dark">
        <tr>
          <th className="tableHeader tableHeaderMain">
            Title
          </th>
          {
            HeaderTitles.map((headerTitle, index)=>{
              var tableColClass = `tableCol${index+2}`;
              var isActive = (activeColumn === tableColClass) ? true : false;
              return(
                <th className={`tableHeader tableHeaderOther ${tableColClass} ${isActive ? "activeCol" : ""}`} key={index} onMouseEnter={()=>onChangeActiveColumn(tableColClass)} onMouseLeave={()=>onChangeActiveColumn("")}>
                  {headerTitle}
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody className="tableBody">
        {
          report.map((obj, index)=>{
            return(
              <tr key={index} className={`animated slideInRight`} style={{"animationDelay": (.05*index)+"s"}}>
                <th className={`tableValues tableValuesMain`}>
                  <div className="tableValue">
                    <strong>{obj.title}</strong>
                  </div>
                </th>
                {
                  obj.report.map((curObj, index)=>{
                    var tableColClass = `tableCol${index+2}`;
                    var isActive = (activeColumn === tableColClass) ? true : false;
                    return(
                      <th key={index} className={`tableValues tableValuesNum tableValuesOthers ${tableColClass} ${isActive ? "activeCol" : ""}`} onMouseEnter={()=>onChangeActiveColumn(tableColClass)} onMouseLeave={()=>onChangeActiveColumn("")}>
                        <div className="tableValue">
                          {curObj.value}
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}







const CONTACT_SCHEDULE = [
  {
    title: "John Jones",
    date: "1610260881987",
    summary: "Interested but was occupied",
  },
  {
    title: "Derren Ireland",
    date: "1610260881987",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Farhaan Rodriguez",
    date: "1610260881987",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Nate Bevan",
    date: "1610260881987",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Ella-Louise Barry",
    date: "1610260881987",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Marshall Acevedo",
    date: "1610260881987",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  }
];



const ContactSchedule = ({person})=>{
  return(
    <div className="contactSchedule">
      <i className="fas fa-user"></i>
      <div className="person">
        <div className="title">
          {person.title}
        </div>
        <div className="date">
          Date: {new Date(Number(person.date)).formatDate("n/d/y H:m")}
        </div>
        <div className="summary">
          <div className="">Notes:</div>
          <div className="">
            {person.summary}
          </div>
        </div>
      </div>
    </div>
  )
}




const MONEY_TRACKING_REPORT_DEMO = {
    "Treatment Plans Full": {
        "Today": 0,
        "This Week": 0,
        "This Month": 0,
        "Last Month": 0,
        "Total": 0
    },
    "Treatment Plans Sub": {
        "Today": 0,
        "This Week": 0,
        "This Month": 0,
        "Last Month": 0,
        "Total": 0
    },
    "Impression Kits": {
        "Today": 0,
        "This Week": 0,
        "This Month": 0,
        "Last Month": 0,
        "Total": 0
    }
}


export default Index;
      
