import React from 'react';
import {NavLink} from 'react-router-dom';

const Component = (props)=>{
  var {onClickLink=()=>{}, MenuToShow, inline, CleanLinks=[]} = props;
  if(!MenuToShow) return null;    
        
  return(
    <div className={`menu ${inline ? "nav" : ""}`}>
      {
        CleanLinks.map((Link, index)=>{
          return(
            <div className="menu-item" key={index}>
              <NavLink className={"nav-link"} 
                exact 
                activeClassName="active" 
                isActive={(match, location) => {     
                                                                      
                  if(!location) return false;
                  if(!location.hash){                    
                    if(!Link.Hash && (location.pathname === Link.Paths[0])) return true;
                  } else {
                    if(location.hash === Link.Hash) return true;
                  }
                  return(false)      
                }}
                onClick={async ()=>{
                  await onClickLink()
                  window.GlobalUtil.triggerEvent("PageChangedEvent", Link);
                }} to={Link.Paths[0]}>{Link.PageName}</NavLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default Component;