import React, { useState } from 'react';
import {GetCouponFromCode} from 'global/utils/helper-functions';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = window.LayoutBuilder;
const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ) 
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartClasses: "hidden", 
      //newOrder: window.GlobalUtil.getCartFromLocalStorage()

    };
    this.HandleCartOrdersKey = window.GlobalUtil.State.subscribe("newOrder", newOrd=>{
      if(!newOrd.coupon) newOrd.coupon = {};
      this.setState({
        newOrder: newOrd
      }, ()=>{
        //window.GlobalUtil.triggerEvent("cartEvent", "show");
      });
    });
    this.CartEvent = window.GlobalUtil.subscribeEvent("cartEvent", actionToTake=>{
      //window.GlobalUtil.consoleLog("actionToTake", [actionToTake]);
      if(actionToTake == "show" && this.state.cartClasses === "hidden"){
        this.setState({cartClasses: "animated slideInRight"}, ()=>{
          clearTimeout(this.timeoutListener);
          this.timeoutListener = setTimeout(()=>{
            this.setState({cartClasses: "active"})
            document.addEventListener("click", this.checkinsideDiv);
          }, 500);
        });
      }
      if(actionToTake == "hide" && this.state.cartClasses === "active"){
        this.setState({cartClasses: "animated slideOutRight"}, ()=>{
          clearTimeout(this.timeoutListener);
          this.timeoutListener = setTimeout(()=>{
            this.setState({cartClasses: "hidden"});
            document.removeEventListener("click", this.checkinsideDiv);
          }, 500);
        });
      }
    });
    this.checkinsideDiv = this.checkinsideDiv.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){
    this.HandleCartOrdersKey.unsubscribe();
    this.CartEvent.unsubscribe();
    clearTimeout(this.timeoutListener);
    document.removeEventListener("click", this.checkinsideDiv);
  }

  checkinsideDiv(e){
    function hasSomeParentTheClass(element, classname) {            
      if (element.className && (element.className.split(' ').indexOf(classname)>=0)) return true;
      return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
    }
    var hasDontClose = false;
    //if(!hasDontClose) hasDontClose = e.target.classList.contains("dontCloseCart");
    if(!hasDontClose) hasDontClose = hasSomeParentTheClass(e.target, "dontCloseCart"); 
    if(!this.CartRef.contains(e.target) && isVisible(this.CartRef) && !hasDontClose) { // or use: event.target.closest(selector) === null
      window.GlobalUtil.triggerEvent("cartEvent", "hide")
    }
  }


  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);           
    var {cartClasses, newOrder} = this.state;
    var hasNothing = (!newOrder || !newOrder.products || newOrder.products.length < 1) ? true : false;
    return (
      <div id="Cart" className={cartClasses} ref={e=>this.CartRef=e}>
        <div className="title">
          {CONTENT[0]}
        </div>
        <div className="cartContent">
          <CartContent newOrder={newOrder}/>
        </div>
        <div className="buttonGroup">
          <button className="button button2" onClick={()=>{window.GlobalUtil.triggerEvent("cartEvent", "hide")}}>{CONTENT[1]}</button>
          <button className={`button button1 ${hasNothing ? "disabled" : ""}`} disabled={hasNothing} onClick={()=>{
            if(hasNothing) return;
            window.Router.history.push('/checkout');
            window.GlobalUtil.triggerEvent("cartEvent", "hide")
          }}>Checkout</button>
        </div>
      </div>
    );
  }
}



class CartContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: window.GlobalUtil.deepGetFromString(this.props, "newOrder,coupon,code", "").toUpperCase()
    };    

    this.ChangedCouponEvent = window.GlobalUtil.subscribeEvent("changedCoupon", ()=>{ //WHEN ASSIGNED COUPON CHANGES THEN RELOAD THIS JUST INCASE
      this.setState({date: new Date()})
    });
  }

  componentDidMount(){
  }

  componentWillUnmount(){
    this.ChangedCouponEvent.unsubscribe()
  }



  onAddCoupon = (e) => {
    this.setState({
      showError: false,
      code: e.target.value.toUpperCase()
    })
  }

  onTestCoupon = (e) => {
    if(e) e.preventDefault();
    var {newOrder={}} = this.props;
    GetCouponFromCode({VARIABLES: {
        code: this.state.code.toLocaleUpperCase()
    }})
    .then((coupon)=>{
      //window.GlobalUtil.consoleLog("coupon", [coupon]);
      if(!coupon._id){
        this.setState({
          showError: true,
          couponReset: true,
          code: window.GlobalUtil.deepGetFromString(this.props, "newOrder,coupon,code", "").toUpperCase()
        }, ()=>{
          this.setState({couponReset: false})
        })
        return;
      } else {
        newOrder.coupon = {...coupon};
        newOrder.products = window.GlobalUtil.productCouponDisCal((newOrder.products ? newOrder.products.slice() : null), newOrder.coupon);        
        window.GlobalUtil.State.set("newOrder", newOrder) 
      }
    });
  }

  render(){
    if(navigator.userAgent === 'ReactSnap') return <div id="CartContent"></div>;
    var {curStatus, code, showError, couponReset} = this.state;  
    var {newOrder={}} = this.props; 
    var {coupon={}, products=[]} = newOrder;  
    
    //var {showSideMenu} = this.state;
    if(!products) products = [];
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);       
          
    return (
      <div id="CartContent">
        <div id="Products">
          <div className="productsTitle">{CONTENT[2]}</div>
          <Products
            products={products}
            onChangeQuantity={(productID, newValue)=>{
              if(!newValue || newValue == 0) newValue = 1;
              products = products.map(product=>{
                if(product.id == productID) product.quantity = newValue;
                return(product)
              })
              newOrder.products = products.slice();
              window.GlobalUtil.State.set("newOrder", newOrder)
            }}
            onRemove={(index)=>{
              products.splice(index, 1);
              newOrder.products = products.slice();
              window.GlobalUtil.State.set("newOrder", newOrder)
            }}
            CONTENT={CONTENT}
          />
        </div>
        {
          (!couponReset) &&
          <div id="Coupons">
            <Coupon
              coupon={coupon}
              code={code}
              showError={showError}
              onAddCoupon={this.onAddCoupon}
              onTestCoupon={this.onTestCoupon}
              CONTENT={CONTENT}
            />
          </div>
        }
        <Summary
          products={products}
          coupon={coupon}
          CONTENT={CONTENT}
        />
      </div>
    );
  }
}





const Products = (props) => {
  var {products=[], CONTENT} = props;   
  return (
    <Row className="">
      <Column className="">
        <div className="products">
          {
            products.length > 0 && products.map((product, index)=>{
              return(
                <Product
                  key={index}
                  product={product}
                  onRemove={()=>props.onRemove(index)}
                  onChangeQuantity={(newValue)=>props.onChangeQuantity(product.id, newValue)}
                  CONTENT={CONTENT}
                />
              )
            })
          }
        </div>
      </Column>
    </Row>
  );
}



const Product = (props) => {
  var {product, CONTENT} = props;  
  if(!product.images) product.images = []; 
  var {discount, cost, originalCost} = window.GlobalUtil.productCostCal(product);           
  return (
    <div className="product d-flex">
      <div className="image">
        <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
      </div>
      <div className="productInfo">
        <div className="name">{product.name}</div>
        <div className="d-flex quantity">
          <div className="quantityInput">
            <input 
              type="number" 
              className={`form-control`}  
              value={product.quantity}
              name={"quantity"}
              min={1}
              onChange={e=>props.onChangeQuantity(e.target.value)}
            />
          </div>
          <i className="fas fa-times"></i>
          <div className="quantityPrice">{window.GlobalUtil.convertToMoney(cost)}</div>
          {/* <div className={"quantityPriceDiscounted"}>{window.GlobalUtil.convertToMoney((cost === originalCost) ? cost : originalCost)}</div>
          <div className="quantityPrice">{window.GlobalUtil.convertToMoney(cost-product.discount)}</div> */}
        </div>
      </div>
      <div className="toss">
        <i className="fas fa-trash-alt dontCloseCart" onClick={props.onRemove}></i>
      </div>
    </div>
  );
}



const Coupon = (props) => {
  var {coupon={}, code="", CONTENT, showError=false} = props; 
  return (
    <Row className="">
      <Column className="">
        <div className="coupon">
          <div className="couponTitle">{CONTENT[3]}</div>
          <form action="" className="couponFunction d-flex" onSubmit={props.onTestCoupon}>
            <input 
              type="text" 
              className={`form-control`}  
              value={code}
              name={"code"}
              onChange={props.onAddCoupon}
              // onBlur={()=>{
              // }}
            />
            <button className={`button button1 ${!code ? "disabled" : ""}`} disabled={!code} onClick={props.onTestCoupon}>{CONTENT[4]}</button>
          </form>
        </div>
        <div className="couponInfo">
          <div className="couponName">
            {
              showError
              ? <span style={{"color": "#E91E63"}}>Coupon Not Found</span>
              : null
            }
            {
              window.GlobalUtil.dangerSet(coupon.description)
            }
          </div>
        </div>
      </Column>
    </Row>
  );
}

  


const Summary = (props) => {
  var {products, coupon, CONTENT} = props;   
  //console.log('products', products);
  
  var {
    subtotal,
    discount,
    tax,
    shipping,
    total
  } = window.GlobalUtil.checkoutCalculatorNew(products, coupon);
  
  return (
    <div id="Summary" className="">
      <Row className="">
        <Column className="">
          <div className="summary">
            <div className="d-flex summaryRow">
              <div className="summaryTitle">{CONTENT[5]}</div>
              <div className="summaryValue">{window.GlobalUtil.convertToMoney(subtotal)}</div>
            </div>
            {
              (discount)
              ? <div className="d-flex summaryRow summaryDiscount">
                  <div className="summaryTitle">{CONTENT[6]}</div>
                  <div className="summaryValue">-{window.GlobalUtil.convertToMoney(discount)}</div>
                </div>
              : null
            }
            <div className="d-flex summaryRow">
              <div className="summaryTitle">{CONTENT[8]}</div>
              <div className="summaryValue">{window.GlobalUtil.convertToMoney(tax)}</div>
            </div>
            <div className="d-flex summaryRow">
              <div className="summaryTitle">{CONTENT[9]}</div>
              <div className="summaryValue">{(shipping ? window.GlobalUtil.convertToMoney(shipping) : "FREE!")}</div>
            </div>
            <div className="d-flex summaryRow summaryTotal">
              <div className="summaryTitle">{CONTENT[10]}</div>
              <div className="summaryValue">{window.GlobalUtil.convertToMoney(total)}</div>
            </div>
          </div>
        </Column>
      </Row>
    </div>
  );
}





export default Index;
export {CartContent};