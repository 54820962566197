import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import 'styles/scss/website/pages/blog.scss';
import {Helmet} from "react-helmet-async";
import {NavLink, Link} from 'react-router-dom';
// import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import CONTENTS from "./CONTENTS";
import Loading from 'global/components/Loading/index';
import CategoryList from '../components/CategoryList/index';
import RecentPosts from '../components/RecentPosts/index';
//import KeywordsList from '../components/KeywordsList/index';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';

const {Container, Row, Column} = window.LayoutBuilder;
const QUERY = API_CALLS.BLOGS.QUERIES.blogPage();

function createMarkup(content) {
  return {__html: content};
}



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      loading: true
    };

    this.PageChangeEventKey = window.GlobalUtil.subscribeEvent("PageChangedEvent", newPage=>{
      this.getBlog();
    })
  }

  componentDidMount(){
    //console.log('componentDidMount');
    window.GlobalUtil.consoleLog("BLOG SINGLE componentDidMount RAN");
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getBlog);
  }

  componentWillUnmount(){
    this.RunAPIOnceWhenReady.unsubscribe();
    this.PageChangeEventKey.unsubscribe();
  }

  onFailed = () => {
    this.setState({curStatus: "failed", loading: false});
  }

  getBlog = async () => {
    //console.log("getBlog");
    window.GlobalUtil.consoleLog("this.getBlog RAN");
    if(!window.Client || !window.Client.query) return false;
    //console.log(this.props.match.params.blogId,"\n\n");
    return await window.Client.query({
      query: QUERY,
      variables: {"url":this.props.match.params.blogId},
      fetchPolicy: "ncache"
    })
    .then(result => {
      //console.log("result");
      //console.log(result,"\n\n");
            
      this.setState({
        blog: result.data.blogPage, 
        loading: false
      });
      return true;
    })
    .catch((error)=>{
      console.log("catch");
      return false;
    })
  }

  
  render(){
    var {blog={}, loading} = this.state;           
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    var HEADER = CONTENTS[window.Session.Language].HEADER; //HOPEFULY WE'LL BE ABLE TO PULL ALL THIS INFO FROM THE BLOG POST ITSELF SO EACH ONE CAN HAVE GREAT SEO
    if(blog.SEO) HEADER = {...HEADER, ...blog.SEO};
    //console.log('blog', blog);
    
    return (
      <div className="contentPage" id="PageBlogSingle">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="breadcrumbsWrapper">
                <div className={`breadcrumbs`}>
                  <Link className="blogLink"  to={((window.Session.Language === "ENGLISH") ? "/" : "/inicio")}><i className="fas fa-home"></i></Link>
                  <div className="dividerIcon"><i className="fas fa-chevron-right"></i></div>
                  <Link className="blogLink"  to={((window.Session.Language === "ENGLISH") ? "/blog" : "/elBlog")}>{CONTENT.BreadCrumbs[1]}</Link>
                  <div className="dividerIcon"><i className="fas fa-chevron-right"></i></div>
                  <div className="lastLink">{window.GlobalUtil.deepGetFromString(this, 'props,match,params,blogId', undefined)}</div>
                </div>
              </div>
            </Column>
          </Row>
          {
            loading
            ? <Row className="">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="">
                    <Loading 
                      //floating="true"
                      header={CONTENT.Loading[0]}
                      body={CONTENT.Loading[1]}
                      inlineStyles={{
                        "LoadingScreen": {
                          "paddingTop": "100px"
                        }
                      }}
                    />
                  </div>
                </Column>
              </Row>
            : <Row className="">
                  <Column className="menuSection d-none d-md-block" col="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <CategoryList withNav={true} />
                    <RecentPosts /> 
                  </Column>
                  <Column className="mainSection" col="" xs="" sm="" md="" lg="" xl="">
                  {
                    (blog && blog._id)
                    ? <React.Fragment>
                        <div className="">
                          <div className="mainImage"><img src={window.GlobalUtil.deepGetFromString(blog, 'mainImage,0,url', undefined)} alt=""/></div>
                          <br/>
                          <h1 className="sectionTitle">{blog.title}</h1>
                          <div className="blogPostMeta">
                            <div className="blogPostMetaDateCategory">{blog.category ? blog.category : ""} - {window.GlobalUtil.dateBasic(blog.duration.startAt)}</div>
                            <div className="blogPostMetaKeywords">{CONTENT.BlogZone[2]}: {blog.keywords && `${blog.keywords.slice(0,5).join(", ")}`}</div>
                          </div>
                        </div>
                        <div className=""><div dangerouslySetInnerHTML={createMarkup(blog.content)} /></div>
                      </React.Fragment>
                    : <div className="pageNotFound">
                        <h2 className="">Page not found</h2>
                        <div className="">Sorry the blog post you are looking for cannot be found. Please return to our blog page.</div>
                        <div className=""><Link className={"button button1"}  to={"/blog"}>Return to Blog</Link></div>
                      </div>
                  }
                  </Column>
                  <Column className="menuSection d-block d-md-none" col="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <CategoryList withNav={true} />
                    <RecentPosts />
                    {/* <KeywordsList/> */}
                  </Column>
                </Row>
          }
        </Container>
        <TreatmentPlanBar/>
      </div>
    );
  }
}






export default Index;
      