const Pages = (Language)=>{
  if(Language === "ENGLISH") return(
    [
      {
        "PageName":"Home",
        "Name":"Home",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths":  ["/","/home"],
        "Menu": ["Header", "Mobile"]
      },
      {
        "PageName":"Get Started",
        "Name":"GetStarted",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/getstarted"],
        "Menu": ["Header", "Mobile"]
      },
      {
        "PageName":"Reset Password",
        "Name":"ResetPassword",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/resetpassword/:token", "/resetpassword"],
        "Menu": []//["Header", "Mobile"]
      },
      {
        "PageName":"Best Aligners",
        "Name":"BestAligners",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/bestaligners"],
        "Menu": ["Header", "Mobile"]
      },
      {
        "PageName":"Pricing",
        "Name":"Pricing",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/pricing"],
        "Menu": ["Header", "Mobile"]
      },
      // {
      //   "PageName":"Testimonials",
      //   "Name":"Testimonials",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/testimonials"],
      //   "Menu": ["None", "Header", "Mobile"]
      // },
      {
        "PageName":"Store",
        "Name":"Store",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/store"],
        "Menu":  [""]
      },
      {
        "PageName":"Blog",
        "Name":"Blog",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/blog"],
        "Menu":  [""]
      },
        {
          "PageName":"Are Clear Aligners Better Than Braces?",
          "Name":"are-clear-aligners-better-than-braces",
          "PageFound": "FrontEnd",
          "ShowOnSiteMap": true,
          "Paths": ["/blog/are-clear-aligners-better-than-braces"],
          "Menu":  [""]
        },
        {
            "PageName":"How to Take a Great Clear Aligner Impression?",
            "Name":"how-to-take-a-great-clear-aligner-impression",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/how-to-take-a-great-clear-aligner-impression"],
            "Menu":  [""]
        },
        {
            "PageName":"Do aligners close gaps? YES!",
            "Name":"do-aligners-close-gaps",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/do-aligners-close-gaps"],
            "Menu":  [""]
        },
        {
            "PageName":"Clear Aligners vs. Invisible Aligners",
            "Name":"clear-aligners-vs-invisible-aligners",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/clear-aligners-vs-invisible-aligners"],
            "Menu":  [""]
        },
        {
            "PageName":"Unmasking the Truth: Hidden Fees in Most Clear Ali...",
            "Name":"hidden-fees-in-most-clear-aligners",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/hidden-fees-in-most-clear-aligners"],
            "Menu":  [""]
        },
        {
            "PageName":"How Invisible Aligners Work? And Do Invisible Alig...",
            "Name":"how-invisible-aligners-work",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/how-invisible-aligners-work"],
            "Menu":  [""]
        },
        {
            "PageName":"Are Clear Aligners the Right Fit for You?",
            "Name":"are-clear-aligners-the-right-fit-for-you",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/are-clear-aligners-the-right-fit-for-you"],
            "Menu":  [""]
        },
        {
            "PageName":"10 Reason why everyone should be using Clear Align...",
            "Name":"10-reasons-why-everyone-should-be-using-clear-alig...",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/10-reasons-why-everyone-should-be-using-clear-alig..."],
            "Menu":  [""]
        },
        {
            "PageName":"10 Misconceptions People Have About Clear Aligners...",
            "Name":"10-misconceptions-people-have-about-clear-aligners",
            "PageFound": "FrontEnd",
            "ShowOnSiteMap": true,
            "Paths": ["/blog/10-misconceptions-people-have-about-clear-aligners"],
            "Menu":  [""]
        },
      {
        "PageName":"Login",
        "Name":"Login",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/login"],
        "Menu": ["Mobile"]
      },
      {
        "PageName":"EmailConfirm",
        "Name":"EmailConfirm",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/emailConfirm"],
        "Menu":  [""]
      },
      {
        "PageName":"EmailUnsubscribe",
        "Name":"EmailUnsubscribe",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/emailUnsubscribe"],
        "Menu":  [""]
      },
      {
        "PageName":"RedirectPage",
        "Name":"RedirectPage",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/rd"],
        "Menu":  [""]
      },
      // {
      //   "PageName":"SimpleSignup",
      //   "Name":"SimpleSignup",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": false,
      //   "Paths": ["/SimpleSignup"],
      //   "Menu":  [""]
      // },
      {
        "PageName":"Checkout",
        "Name":"Checkout",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/checkout"],
        "Menu":  [""]
      },
      {
        "PageName":"Impression Kit",
        "Name": "ImpressionKit",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/impressionKit"],
        "Menu": ["Footer2", "Mobile"]
      },
      {
        "PageName":"Faq",
        "Name":"Faq",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/faq"],
        "Menu": ["Footer1", "Mobile"]
      },
      {
        "PageName": "About",
        "Name": "About",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/about", "/aboutus"],
        "Menu": ["Footer1", "Mobile"]
      },
      {
        "PageName":"Contact",
        "Name":"Contact",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/contact"],
        "Menu": ["Footer1", "Mobile"]
      },
      {
        "PageName":"Consent",
        "Name":"Consent",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/consent"],
        "Menu": ["Footer2", "Mobile"]
      },
      {
        "PageName":"Privacy",
        "Name":"Privacy",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/privacy"],
        "Menu": ["Footer2", "Mobile"]
      },
      {
        "PageName":"Terms of Use",
        "Name":"Terms",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/termsofuse"],
        "Menu": ["Footer2", "Mobile"]
      },
      {
        "PageName":"Guarantees",
        "Name":"Guarantees",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/guarantees"],
        "Menu": ["Footer2", "Mobile"]
      },
      {
        "PageName":"Giveaway",
        "Name": "ClearAligners",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/giveaways"],
        "Menu":  [""] //["Footer1", "Header", "Mobile"]
      },
      {
        "PageName":"Promo",
        "Name":"Promo",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/promo"],
        "Menu":  ["Header", "Footer1"]
      },
      {
        "PageName":"BuyKit",
        "Name":"BuyKit",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/buykit"],
        "Menu":  [""]
      },
      {
        "PageName":"Buy Now",
        "Name":"BuyNow",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/buy"],
        "Menu":  ["Footer1"]
      },
      {
        "PageName":"Page404",
        "Name":"Page404",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/404"],
        "Menu": [""]
      },
      // {
      //   "PageName":"SubmitBeforeAndAfter",
      //   "Name":"SubmitBeforeAndAfter",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/submit-before-and-after"],
      //   "Menu": ["Footer2"]
      // },
      {
        "PageName":"CustomLandingPage1",
        "Name":"CustomLandingPage1",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/deals001"],
        "Menu": [""]
      },
      {
        "PageName":"CustomLandingPage2",
        "Name":"CustomLandingPage2",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/deals002"],
        "Menu": [""]
      },
      {
        "PageName":"CustomLandingPage3",
        "Name":"CustomLandingPage3",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/deals003"],
        "Menu": [""]
      },
      // {
      //   "PageName": "Winners",
      //   "Name": "Winners",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/winners"],
      //   "Menu":  ["Footer1", "Mobile"]
      // },

      //USER PAGES------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      {
        "PageName": "Dashboard",
        "Name": "Dashboard",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-tachometer-alt"
      },
      {
        "PageName": "TreatmentPlan",
        "Name":"TreatmentPlan",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/treatmentplan"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-file-contract"
      },
      {
        "PageName": "Affiliate",
        "Name": "Affiliate",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/affiliate"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-user-astronaut"
      },
      {
        "PageName": "Profile",
        "Name": "Profile",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/profile"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-user"
      },
      {
        "PageName": "Billing",
        "Name": "Billing",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/billing"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-money-check"
      },
      {
        "PageName": "Help",
        "Name": "Help",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/help"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-question-circle"
      },
      //ADMIN PAGES------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      {
        "PageName":"Home",
        "Name":"Home",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-tachometer-alt",
        "Paths": ["/admin"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"To Ship",
        "Name":"Shipping",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-shipping-fast",
        "Paths": ["/admin/shipping"],
        "GlobalStateVar": ["orderCount"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Treatment Plans",
        "Name":"TreatmentPlans",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-file-medical-alt",
        "Paths": ["/admin/plans"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Aligners",
        "Name":"Aligners",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-tooth",
        "Paths": ["/admin/aligners"],
        "GlobalStateVar": [],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Orders",
        "Name":"Orders",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-cash-register",
        "Paths": ["/admin/orders"],
        "GlobalStateVar": [],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Blogs",
        "Name":"Blogs",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fab fa-blogger",
        "Paths": ["/admin/blogs"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Coupons",
        "Name":"Coupons",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-tags",
        "Paths": ["/admin/coupons"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Products",
        "Name":"Products",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-shopping-cart",
        "Paths": ["/admin/products"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Referrals",
        "Name":"Referrals",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-people-arrows",
        "Paths": ["/admin/referrals"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Tracking",
        "Name":"Tracking",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-chart-line",
        "Paths": ["/admin/tracking"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Affiliates",
        "Name":"Affiliates",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-user-ninja",
        "Paths": ["/admin/affiliates"],
        "GlobalStateVar": [],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Giveaways",
        "Name":"Giveaways",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-trophy",
        "Paths": ["/admin/giveaways"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Users",
        "Name":"Users",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-users",
        "Paths": ["/admin/users"],
        "GlobalStateVar": [],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Coms",
        "Name":"Coms",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-comments",
        "Paths": ["/admin/coms"],
        "GlobalStateVar": [],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Failed Alerts",
        "Name":"Failed",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-flag-checkered",
        "Paths": ["/admin/configure/failed"],
        "Menu": [""]
      },
      {
        "PageName":"Configure",
        "Name":"Settings",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-sliders-h",
        "Paths": ["/admin/configure"],
        "Menu": ["ADMIN", "Mobile"]
      },
      {
        "PageName":"Emails",
        "Name":"Emails",
        "PageFound": "AdminEnd",
        "ShowOnSiteMap": false,
        "Icon": "fas fa-envelope",
        "Paths": ["/admin/configure/emails"],
        "Menu": [""]
      }
    ]
  );
  return (
    [
      {
        "PageName":"Inicio",
        "Name":"Inicio",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/","/inicio"],
        "Menu": ["EspHeader", "EspMobile"]
      },
      {
        "PageName":"Comencemos",
        "Name":"Comencemos",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/comencemos"],
        "Menu": ["EspHeader", "EspMobile"]
      },
      {
        "PageName":"Restablecer la Contraseña",
        "Name":"RestablecerContra",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/restablecerlacontrasena/:token", "/restablecerlacontrasena"],
        "Menu": []//["Header", "Mobile"]
      },
      {
        "PageName":"Mejores Alineadores",
        "Name":"MejoresAlineadores",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/mejoresalineadores"],
        "Menu": ["EspHeader", "EspMobile"]
      },
      {
        "PageName":"Precios",
        "Name":"Precios",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/precios"],
        "Menu": ["EspHeader", "EspMobile"]
      },
      {
        "PageName":"Compra Kit",
        "Name":"CompraKit",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/comprakit"],
        "Menu": ["SiteMap"]
      },
      // {
      //   "PageName":"Testimonials",
      //   "Name":"Testimonials",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/testimonials"],
      //   "Menu": ["None", "Header", "Mobile"]
      // },
      // {
      //   "PageName":"Testimonios",
      //   "Name":"Testimonios",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/testimonios"],
      //   "Menu": ["None", "EspHeader", "EspMobile"]
      // },
      // {
      //   "PageName":"Store",
      //   "Name":"Store",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/store"],
      //   "Menu": ["None"]
      // },
      {
        "PageName":"Tienda",
        "Name":"Tienda",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/tienda"],
        "Menu": ["None"]
      },
      // {
      //   "PageName":"Blog",
      //   "Name":"Blog",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/blog"],
      //   "Menu": ["None"]
      // },
      // {
      //   "PageName":"El Blog",
      //   "Name":"ElBlog",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/elblog"],
      //   "Menu": ["None"]
      // },
      {
        "PageName":"Acceso",
        "Name":"Acceso",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/acceso"],
        "Menu": ["EspMobile"]
      },
      {
        "PageName":"Confirmar Email",
        "Name":"ConfirmarEmail",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/confirmaremail"],
        "Menu": ["None"]
      },
      {
        "PageName":"EmailUnsubscribe",
        "Name":"EmailUnsubscribe",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/emailUnsubscribe"],
        "Menu": ["None"]
      },
      {
        "PageName":"RedirectPage",
        "Name":"RedirectPage",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/rd"],
        "Menu": ["None"]
      },
      // {
      //   "PageName":"SimpleSignup",
      //   "Name":"SimpleSignup",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/SimpleSignup"],
      //   "Menu": ["None"]
      // },
      {
        "PageName":"Checkout",
        "Name":"Checkout",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/checkout"],
        "Menu": ["None"]
      },
      {
        "PageName":"Kit De Impresion",
        "Name": "KitDeImpresion",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/kitDeImpresion"],
        "Menu": ["EspFooter2", "EspMobile"]
      },
      {
        "PageName":"PMF",
        "Name":"PMF",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/pmf"],
        "Menu": ["EspFooter1", "EspMobile"]
      },
      {
        "PageName":"Nosotros",
        "Name":"SobreNosotros",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/sobrenosotros"],
        "Menu": ["EspFooter1", "EspMobile"]
      },
      {
        "PageName":"Contacto",
        "Name":"Contacto",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/contacto"],
        "Menu": ["EspFooter1", "EspMobile"]
      },
      {
        "PageName":"Consentimiento",
        "Name":"Consentimiento",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/consentimiento"],
        "Menu": ["EspFooter2", "EspMobile"]
      },
      {
        "PageName":"Política de Privacidad",
        "Name":"PolíticaDePrivacidad",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/privacidad"],
        "Menu": ["EspFooter2", "EspMobile"]
      },
      {
        "PageName":"Condiciones de Uso",
        "Name":"CondicionesDeUso",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/condicionesdeuso"],
        "Menu": ["EspFooter2", "EspMobile"]
      },
      {
        "PageName":"Garantias",
        "Name":"Garantias",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/garantias"],
        "Menu": ["EspFooter2", "EspMobile"]
      },
      {
        "PageName":"Sorteo",
        "Name":"Alineadores",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/regalos"],
        "Menu":  [""]//["EspFooter1", "EspHeader", "EspMobile"]
      },
      {
        "PageName":"Promocion",
        "Name":"Promocion",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/promocion"],
        "Menu":  ["EspHeader", "EspFooter1"]
      },
      {
        "PageName":"PromocionReportes",
        "Name":"PromocionReportes",
        "PageFound": "FrontEnd",
        "ShowOnSiteMap": true,
        "Paths": ["/promocionReportes"],
        "Menu":  []
      },
      // {
      //   "PageName": "Ganadores",
      //   "Name": "Ganadores",
      //   "PageFound": "FrontEnd",
      //   "ShowOnSiteMap": true,
      //   "Paths": ["/ganadores"],
      //   "Menu":  ["EspFooter1", "EspMobile"]
      // },

      //USER PAGES------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      {
        "PageName": "Inicio",
        "Name": "Inicio",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user"],
        "Menu": ["HeaderESP", "MobileESP"],
        "Icon": "fas fa-tachometer-alt"
      },
      {
        "PageName": "Tratamiento",
        "Name": "Tratamiento",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/tratamiento"],
        "Menu": ["HeaderESP", "MobileESP"],
        "Icon": "fas fa-file-contract"
      },
      {
        "PageName": "Afiliado",
        "Name": "Afiliado",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/afiliado"],
        "Menu": ["Header", "Mobile"],
        "Icon": "fas fa-user-astronaut"
      },
      {
        "PageName": "Perfil",
        "Name": "Perfil",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/perfil"],
        "Menu": ["HeaderESP", "MobileESP"],
        "Icon": "fas fa-user"
      },
      {
        "PageName": "Facturación",
        "Name": "Facturacion",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/facturacion"],
        "Menu": ["HeaderESP", "MobileESP"],
        "Icon": "fas fa-money-check"
      },
      {
        "PageName": "Ayuda",
        "Name": "Ayuda",
        "PageFound": "UserEnd",
        "ShowOnSiteMap": false,
        "Paths": ["/user/ayuda"],
        "Menu": ["HeaderESP", "MobileESP"],
        "Icon": "fas fa-question-circle"
      }
    ]
  )
}


export default Pages;