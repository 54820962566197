import React, { useState, useRef } from 'react';
import TemplateSlideInRight from "global/templates/templateSlideInRight/index";
import API_CALLS from 'API_CALLS/index';
import {QRCodeSVG} from 'qrcode.react';
import FormBuilder from 'global/components/FormBuilder/index';
const {Container, Row, Column} = window.LayoutBuilder;
const PREVIEWUSER = API_CALLS.USERS.PREVIEW;
const PREVIEWPLAN = API_CALLS.TREATMENT_PLANS.PREVIEW;
const PREVIEWALIGNER = API_CALLS.ALIGNERS.PREVIEW;



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      Plan: {}, 
      hideForm: false, 
      ShowPreview: false,
      isLoading: false //WHEN LOADING FILES 
    };    
    this.APICompleteTask = this.APICompleteTask.bind(this);
    this.onClose = this.onClose.bind(this);
    this.SlideInRight = this.SlideInRight.bind(this);
    this.FormBuilderZone = this.FormBuilderZone.bind(this);
    this.Table = this.Table.bind(this);
    this.PREVIEWUSER = PREVIEWUSER;
    this.PREVIEWPLAN = PREVIEWPLAN;
    this.PREVIEWALIGNER = PREVIEWALIGNER;
    this.curObj = {
      "status": "PENDING"
    };
    this.ComponentToPrint = ComponentToPrint;
    this.FromLabel = FromLabel;
    this.ToLabel = ToLabel;
    this.UploadingFilesEventKey = window.GlobalUtil.subscribeEvent("UploadingFiles", isLoading=>{
      this.setState({isLoading});
    });
  }
  
  componentDidMount(){    
    window.APIGetAgain = this.APIGet;
    this.APIGet();
  }

  componentWillUnmount(){
    this.UploadingFilesEventKey.unsubscribe();
    window.APIGetAgain = undefined;
    clearTimeout(this.timeoutListener);
  }

  APICompleteTask(){
    window.CurRefetchList(true);
    this.onClose();
  }



  onClose(slideInRightRef){
    window.CurRefetchList(true); //RELOAD LIST
    window.CurRefetchSideNavNumbers(); //RELOAD SIDE MENU NUMBERS
    
    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose(); //ANIMATION SLIDE OUT
    if(this.props.onClose) this.props.onClose(); //CLOSE THIS IN PARENT
  }

  previewUser=(UserObj)=>{          
    var PREVIEWUSER = this.PREVIEWUSER;
    return(
      <Row>
        <Column style={{"paddingBottom":"0px"}}>
          <div className="previewFormFieldTitles d-flex">
            <div style={{"flex":"1"}}>
              <div className="previewFormFieldTitle">User</div>
              <div className="previewFormFieldSubTitle">
                <span style={{"fontWeight":"600"}}>Name</span>{`: ${window.GlobalUtil.deepGetFromString(UserObj, "firstName", "")} ${window.GlobalUtil.deepGetFromString(UserObj, "lastName", "")}`}&nbsp;
                <span style={{"paddingLeft":"40px","fontWeight":"600"}}>ID: &nbsp;</span> 
                {UserObj._id}
              </div>
            </div>
            <div className="viewFullButtonWrapper">
              <button className="button button1 btn-color5 viewFullButton" onClick={()=>{
                this.setState({
                  ShowPreview: ()=><PREVIEWUSER
                      VARIABLES={{
                        id: UserObj._id
                      }}
                    />
                });
              }}>View Full <i className="fas fa-eye"></i></button>
            </div>
          </div>
        </Column>
      </Row>
    )
  }


  previewPlan=(PlanObj)=>{          
    var PREVIEWPLAN = this.PREVIEWPLAN;
    return(
      <Row>
        <Column style={{"paddingBottom":"0px"}}>
          <div className="previewFormFieldTitles d-flex">
            <div style={{"flex":"1"}}>
              <div className="previewFormFieldTitle">Treatment Plan</div>
              <div className="previewFormFieldSubTitle">Treatment Plan ID:&nbsp;{PlanObj._id}</div>
            </div>
            <div className="viewFullButtonWrapper">
              <button className="button button1 btn-color5 viewFullButton" onClick={()=>{
                this.setState({
                  ShowPreview: ()=><PREVIEWPLAN
                    VARIABLES={{
                      id: PlanObj._id
                    }}
                  />
                });
              }}>View Full <i className="fas fa-eye"></i></button>
            </div>
          </div>
        </Column>
      </Row>
    )
  }


  FormBuilderZone(FORM_FIELDS){
    var {hideForm} = this.state;
    return (
      <Container>
        <Row>
          <Column 
            className={""}>
            {
              (!hideForm && this.curObj && this.curObj.id) && 
              <div >
                <Container className="">
                  <FormBuilder
                    disableKeySubmit={true}
                    initialValues={this.curObj}
                    listOfFields={FORM_FIELDS(this.curObj)}
                    onSubmit={()=>{
                    }}
                    onChange={newObj => {
                      this.curObj = {...this.curObj, ...newObj};
                      //console.log("this.curObj","\n\n",this.curObj,"\n\n");
                      if(this.timeoutListener) clearTimeout(this.timeoutListener);
                      this.timeoutListener = setTimeout(()=>{
                        this.validate(true)
                        clearTimeout(this.timeoutListener)
                      }, 1000);   
                            
                    }}
                    onValidate={(isValid)=>{
                      this.setState({isValid})
                    }}
                  />
                </Container>
              </div>
            }
          </Column>
        </Row>
      </Container>
    )
  }


  Table(props){
    return(
      <div className="previewFormTable">
        <table className="table">
          <thead className="thead">
            <tr>
              {
                props.HEADERS &&
                props.HEADERS.map((headVal, index)=>{
                  var isFirstOrLast = false;
                  if((!index)||(props.HEADERS.length == (index+1))) isFirstOrLast = true;
                  return(
                    <th key={index} scope="col" style={isFirstOrLast ? {"width": "100px", "textAlign":"center"} : {}}>
                      <div className={`headerColWrapper`}>
                        {headVal}
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
          {
            props.ROWS &&
            props.ROWS.map((fields, index)=>{    
              return(
                <tr key={index} className={`animated fadeIn`}>
                  {
                    fields &&
                    fields.map((field, index)=>{
                      var isFirstOrLast = false;
                      if((!index)||(fields.length == (index+1))) isFirstOrLast = true;
                      return(
                        <td key={index} scope="row" style={isFirstOrLast ? {"width": "100px", "textAlign":"center", "verticalAlign": "middle"} : {"verticalAlign": "middle"}}>
                          <div className="rowText">
                            {field}
                          </div>
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    )
  }


  SlideInRight(TITLE, BUTTONS, FIELDS){
    var {isValid, ShowPreview} = this.state;
    return (
      <TemplateSlideInRight
        ref={e=>this.slideInRightRef=e}
        title={TITLE}
        isValid={isValid}
        onClose={this.onClose}
        onSave={this.validate}
        customButtons={BUTTONS}>
        <React.Fragment>
          {FIELDS()}
          {
            ShowPreview &&
            <PreviewSlideOut
              onClose={()=>{
                this.setState({ShowPreview: undefined});
              }}
              showFunction={ShowPreview}
            />
          }
        </React.Fragment>
      </TemplateSlideInRight>
    )
  }
}






const PreviewSlideOut = (props)=>{
  return(
    <TemplateSlideInRight
      title={"View Preview"}
      onClose={props.onClose}
      onSave={props.validate}
      customButtons={(curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2" onClick={()=>{
              props.onClose()
            }}>Close</button>
          </React.Fragment>
        )
      }}
      >
      {props.showFunction()}
    </TemplateSlideInRight>
  )
}






const PRINT_STYLES = {
  toFrom: {padding: "0 0 10px 0", "fontSize": "18px", "textTransform":"uppercase", "fontWeight":"700"},
  printWrapper: {padding: "20px 0px 0px 40px", "width": "400px"},
  printField: {"fontWeight":"600","fontSize":"16px"},
  dFlex: {"display":"flex"},
  flex1: {"flex":"1"}
};



class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    var {User={}} = this.props;    
    return (
      <div className="" style={PRINT_STYLES.printWrapper}> 
        <FromLabel />
        <br/>
        <ToLabel User={User}/>
        <br/><br/>
      </div>
    );
  }
}



const FromLabel = (props)=>{
  var ComanyAddress = ((window.SiteSettings && window.SiteSettings.address && window.SiteSettings.address.street) ? window.SiteSettings.address : {});
  return(
    <div>
      <div style={PRINT_STYLES.toFrom}>From:</div>
      <div style={PRINT_STYLES.dFlex}>
        <div style={PRINT_STYLES.flex1}>
          <div className="printField" style={PRINT_STYLES.printField}>
            {window.SiteSettings.companyName}
          </div>
          <div className="printField" style={PRINT_STYLES.printField}>
            {ComanyAddress.street}
          </div>
          {
            ComanyAddress.street2 && 
            <div className="printField" style={PRINT_STYLES.printField}>
              {ComanyAddress.street2}
            </div>
          }
          <div className="printField" style={PRINT_STYLES.printField}>
            {ComanyAddress.city}, {ComanyAddress.state}, {ComanyAddress.zipcode}
          </div>
          {
            ComanyAddress.country && 
            <div className="printField" style={PRINT_STYLES.printField}>
              {ComanyAddress.country}
            </div>
          }
        </div>
        <div style={{}}>
          <QRCodeSVG 
            value={`${window.SiteSettings.companyName}, ${ComanyAddress.street}, ${ComanyAddress.street2 ? ComanyAddress.street2 + ", " : ''}${ComanyAddress.city}, ${ComanyAddress.state}, ${ComanyAddress.zipcode}, ${ComanyAddress.country ? ComanyAddress.country : 'USA'}`} 
          />
        </div>
      </div>
    </div>
  )
}




const ToLabel = (props)=>{
  var {User={}} = props;
  var {address={}} = User;
  if(!address) address = {};
  return(
    <div>
      <div style={PRINT_STYLES.toFrom}>Ship To:</div>
      <div style={PRINT_STYLES.dFlex}>
        <div style={PRINT_STYLES.flex1}>
          <div className="printField" style={PRINT_STYLES.printField}>
            {User.firstName} {User.lastName}
          </div>
          <div className="printField" style={PRINT_STYLES.printField}>
            {address.street}
          </div>
          {
            address.street2 && 
            <div className="printField" style={PRINT_STYLES.printField}>
              {address.street2}
            </div>
          }
          <div className="printField" style={PRINT_STYLES.printField}>
            {address.city}, {address.state}, {address.zipcode}
          </div>
          {
            address.country && 
            <div className="printField" style={PRINT_STYLES.printField}>
              {address.country}
            </div>
          }
        </div>
        <div style={{}}>
          <QRCodeSVG 
            value={`${User.firstName} ${User.lastName}, ${address.street}, ${address.street2 ? address.street2 + ", " : ''}${address.city}, ${address.state}, ${address.zipcode}, ${address.country ? address.country : 'USA'}`} 
          />
        </div>
      </div>
    </div>
  )
}
export default Index;