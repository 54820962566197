import React, { useState } from 'react';
//import { Editor } from '@tinymce/tinymce-react';
import Editor from 'react-simple-wysiwyg';
import {TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses.js";
import SlideInRight from "global/components/SlideInRight/index";
import { gql, useQuery, useMutation } from '@apollo/client';
import axios from "axios";

//onDelete
//IMAGEUTILS.DeleteImage(imageURL, ()=>window.tempSubmit())
const MUTATION_GET_S3_SIGN = gql`
  mutation GetS3Sign(
    $filename: String!,
    $filetype: String!,
  ) {
     getS3Sign(input:{
      filename: $filename,
      filetype: $filetype,
      }){
        signedRequest
        url
      }
  }
`;



class ControlledEditorSlideIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlideInRight: false
    };
    this.onClose = this.onClose.bind(this);
    this.Validate = this.Validate.bind(this);
  }

  componentDidMount(){          
    this.Validate(this.props.value);
  }

  onClose(save){
    if(save && this.slideInRightRef2){
      this.slideInRightRef2.onSave();
      this.setState({
        showSlideInRight: false
      })
    } else {
      this.setState({
        showSlideInRight: false
      })
    }
  }


  Validate(newValue){
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    this.props.onChangeValid(name, valid);
  }

  render() {
    const { showSlideInRight } = this.state;
    var {field={}, id, value='', onChange, onTouch, meta} = this.props;
    var {name, required, title, subText} = field;
    return (
      <div>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)} 
        <div className="button button1" onClick={()=>this.setState({showSlideInRight: true})}>Edit Content</div>
        {
          showSlideInRight &&
          <SlideInRight 
            ref={e=>this.slideInRightRef=e}
            showOverlay={true}
            width="1000px"
            maxWidth="90%"
            header={<div className="title">Content Editor</div>}
            footer={<div className="buttonGroup">
              <button className="button button2" onClick={()=>this.onClose()}>Cancel</button>
              <button className="button button1" onClick={()=>this.onClose("save")}>Save</button>

            </div>}
            onClose={()=>this.onClose("save")}>
            <ControlledEditor
              {...this.props}
              ref={e=>this.slideInRightRef2=e}
              Validate={this.Validate}
            />
          </SlideInRight>
        }
        {subText}
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


class ControlledEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: (this.props.value ? this.props.value : ""),
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount(){          
    this.setState({loadingDone: true}) //WITHOUT THIS WE GET AN ERROR ABOUT LOADING
  }

  onEditorStateChange(editorState){          
    this.setState({
      editorState: editorState.target.value
    })
  };


  

  onSave(){
    var {editorState} = this.state;
    this.props.onChange(this.props.field.name, editorState);
    if(this.props.field.required) this.props.Validate(editorState);
  }

  render() {
    const { editorState, loadingDone } = this.state;         
    var {field={}, id, value='', onChange, onTouch, meta} = this.props;
    var {name, required, title, subText} = field;
    return (
      <div className="wizyWig">
        {
          loadingDone &&
          <Editor value={editorState} onChange={this.onEditorStateChange} />
          // <Editor 
          //   apiKey={"gt0c16y1g587r5bim4r7kprp3fb91qc1lfy4pelsw7e2su5h"} 
          //   init={{
          //     height: 1000,
          //     plugins: [
          //       "advlist autolink lists link image charmap print preview anchor",
          //       "searchreplace visualblocks code fullscreen",
          //       "insertdatetime media table paste"
          //     ],
          //     // images_upload_url: 'postAcceptor.php',
          //     // images_upload_base_path: '/some/basepath',
          //     // images_upload_credentials: true
          //     images_upload_handler: function (blobInfo, success, failure) {
          //       const API_UPLOAD_FILE = function(file, url, signedRequest){
          //         const options = {
          //           headers: {
          //             "Content-Type": file.type,
          //             'Access-Control-Allow-Origin': '*',
          //             'Access-Control-Allow-Methods': 'GET'
          //           }
          //         };
                        
          //         axios.put(signedRequest, file, options)
          //           .then(obj=>{
          //             success(url);
          //           })
          //           .catch(error=>{
          //             console.log("error");   
          //             failure(error)
          //           });
          //       }

          //       const API_GET_SIGNATURE = function(e){
          //         console.log("e");
          //         console.log(e,"\n\n");
                        
          //         const file = e.blob();
          //         console.log("file");
          //         console.log(file,"\n\n");
                        
          //         const CurrentTime = new Date().getTime();
          //         window.Client.mutate({
          //           mutation: MUTATION_GET_S3_SIGN,
          //           variables: {
          //             filename: `images/BLOG_POSTS/blog/postImage_${CurrentTime}`,
          //             filetype: file.type
          //           }
          //         })
          //         .then(result => {
          //           var {signedRequest, url} = window.GlobalUtil.deepGetFromString(result, "data,getS3Sign", {});
          //           // console.log("signedRequest, url");
          //           // console.log(signedRequest, url,"\n\n");
          //           if(!signedRequest || !url){
          //             console.log("FAILED TO GET UPLOAD SIGNED REQUEST");
          //             return;
          //           }
          //           // console.log("result.data.getS3Sign");
          //           // console.log(result.data,"\n\n");
          //           API_UPLOAD_FILE(file, url, signedRequest);      
          //         });
          //       }
          //       API_GET_SIGNATURE(blobInfo);
          //     }
          //   }}
          //   value={editorState} 
          //   onEditorChange={this.onEditorStateChange} 
          // />
        }
      </div>
    )
  }
}


export default ControlledEditorSlideIn;