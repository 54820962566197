import React from 'react';
import "styles/scss/website/index.scss";
// import GiveawayBanner from "website/components/GiveawayBanner2/index";

import Home from './pages/home/index';
import About from './pages/about/index';
import BestAligners from './pages/bestAligners/index';
import Blog from './pages/blog/index';
import BlogDynamic from './pages/blog/single/index';
import Contact from './pages/contact/index';
import Consent from './pages/consent/index';
import Faq from './pages/faq/index';
import Guarantees from './pages/guarantees/index';
import Login from './pages/login/index';
import Pricing from './pages/pricing/index';
import Page404 from './pages/404/index';
import Privacy from './pages/privacy/index';
import Store from './pages/store/index';
import StoreDynamic from './pages/store/single/index';
import Terms from './pages/terms/index';
//import Testimonials from './pages/testimonials/index';
import GetStarted from './pages/getStarted/index';
import Winners from './pages/winners/index';

import EmailConfirm from './pages/actionpages/emailConfirm';
import EmailUnsubscribe from './pages/actionpages/emailUnsubscribe';

import Promo from './pages/promotions/index';

import ResetPassword from './pages/resetPassword/index';
// import RestablecerContra from './pages/resetPassword/index';

import RedirectPage from './pages/redirectPage';

//FOR GIVEAWAYS
import ClearAligners from './pages/giveaways';
// import Alineadores from './pages/giveaways';


import ImpressionKit from './pages/impressionKit/index';
import BuyKit from './pages/buyImpressionKit';
import BuyNow from './pages/buyNow';
//import SubmitBeforeAndAfter from './pages/submitBeforeAndAfter/index'; //ADD THIS ONCE WE HAVE SOMETHING TO GIVE AWAY



//import SimpleSignup from './pages/userSimpleSignup/index';
import ContactForm from 'global/components/ContactForm/index';
import PAGES from './pages/pages.js'; //ADD NEW PAGES HERE
import {
    //BrowserRouter as Router, 
    //StaticRouter, 
    Route, 
    //Link, 
    Redirect, Switch
    // etc.
} from 'react-router-dom';
import { withRouter } from "react-router";




import Header from 'website/components/Header/index';
import Footer from 'website/components/Footer/index';
import MobileMenu from 'website/components/MobileMenu/index';


const PAGES_COMPONENTS = {
  "About": About,
  "BestAligners": BestAligners,
  "Blog": Blog,
  "Consent": Consent,
  "Contact": Contact,
  "GetStarted": GetStarted,
  "Faq": Faq,
  "Guarantees": Guarantees,
  "Login": Login,
  "Pricing": Pricing,
  "Page404": Page404,
  "Privacy": Privacy,
  "Terms": Terms,
  "Store": Store,
  //"Testimonials": Testimonials,
  
  "EmailConfirm": EmailConfirm,
  "EmailUnsubscribe": EmailUnsubscribe,
  "ResetPassword": ResetPassword,
  "Promo": Promo,
  //"Remision": Referral,
  //"SimpleSignup": SimpleSignup,
  //"Tienda": Store,  
  
  "RedirectPage": RedirectPage,
  
  "ClearAligners": ClearAligners,
  "Winners": Winners,
  "ImpressionKit": ImpressionKit,
  "BuyNow": BuyNow,
  "BuyKit": BuyKit,
  //"SubmitBeforeAndAfter": SubmitBeforeAndAfter,
  "Home": Home
};



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideMenu: false,
      showContactForm: false
    };    
    this.ShowContactFormKey = window.GlobalUtil.subscribeEvent("ShowContactForm", showOrNot=>{
      this.setState({showContactForm: showOrNot});
    });
    this.getParmsAndSetVals = this.getParmsAndSetVals.bind(this);
  }
 
  componentDidMount(){
    this.getParmsAndSetVals();
    this.onStartBotSonic();
  }

  componentWillUnmount(){
    this.ShowContactFormKey.unsubscribe();
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //console.log(`this.curPage`,"\n\n",this.curPage,"\n\n");        
      window.GlobalUtil.triggerEvent("PageChangedEvent", this.curPage);
    }
  }

  getParmsAndSetVals(){
    window.GlobalUtil.getPageParams()
      .then((changed)=>{
        if(changed && this) this.setState({date: new Date()});
      });
  }

  onStartBotSonic=()=>{
    if(navigator && (navigator.userAgent === 'ReactSnap')) return;
    if(!window.ImportBotSonic && !document.querySelector("#Botsonic")){
      var ImportBotSonic = (w, d, s, o, f, js, fjs) => {
        w["botsonic_widget"] = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        js = d.createElement(s);
        fjs = d.getElementsByTagName(s)[0];
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      }
      ImportBotSonic(window, document, "script", "Botsonic", "https://widget.writesonic.com/CDN/botsonic.min.js"); //
      // <script id="Botsonic" src="https://widget.writesonic.com/CDN/botsonic.min.js" async=""></script>
    }
    window.ImportBotSonic = true;
    if(window.Botsonic && (typeof window.Botsonic === "function")) window.Botsonic("init", {
      serviceBaseUrl: "https://api.botsonic.ai",
      token: "0c28994a-c673-44b7-a8bb-0ee1dda3478d",
    });
  }

  render(){          
    var {showSideMenu, showContactForm} = this.state;
    var {data, location={pathname: ''}} = this.props;    
    var pathname = location.pathname.toLowerCase();
    const PAGES_LIST = PAGES();
    return (
      <div id="Website">
        {
          showSideMenu && <MobileMenu onHideSideMenu={()=>this.setState({showSideMenu: false})}/>
        }
        <Header onShowSideMenu={()=>this.setState({showSideMenu: true})}/>
        {
          ((pathname === "/comprakit") || (pathname === "/buykit") || (pathname === "/comprakit/") || (pathname === "/buykit/"))
          ? null
          : null //<GiveawayBanner/>
        }
        <div id="Content">
          <Switch>
            <Route 
                path={"/blog/:blogId"} 
                render={props=>{
                  this.curPage = {
                    PageName: "Blog Page",
                    Name: "Blog",
                    DynamicID: window.GlobalUtil.deepGetFromString(props, "match,params,blogId", "Failed"),
                    Paths: [`/blog/:${window.GlobalUtil.deepGetFromString(props, "match,params,blogId", "Failed")}`]
                  };
                  return<BlogDynamic  {...props}/>
                }} 
              />
            <Route 
                path={"/store/:storeId"} 
                render={props=>{
                  this.curPage = {
                    PageName: "Store Page",
                    Name: "Store",
                    DynamicID: window.GlobalUtil.deepGetFromString(props, "match,params,storeId", "Failed"),
                    Paths: [`/store/:${window.GlobalUtil.deepGetFromString(props, "match,params,storeId", "Failed")}`]
                  };
                  return<StoreDynamic  {...props}/>
                }} 
              />
            {
              PAGES_LIST.map((page, index)=>{       
                if(!PAGES_COMPONENTS) return null;                         
                if(!PAGES_COMPONENTS[page.Name]) return null;
                const CurComp = PAGES_COMPONENTS[page.Name];
                if(page.Name === "Home" || page.Name == "Inicio" || page.Name == "Blog") return <Route 
                  exact 
                  strict 
                  key={page.Name}
                  path={page.Paths} 
                  render={props=>{
                    this.curPage = page;                  
                    return <CurComp {...props} pageInfo={page}/>
                  }} 
                />

                return <Route 
                  key={page.Name}
                  path={page.Paths} 
                  render={props=>{
                    this.curPage = page;
                    return <CurComp {...props}  pageInfo={page}/>
                  }} 
                />
              })
            } 
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
          
        <Footer onShowContact={()=>{if(this.HomeRef) this.HomeRef.onShowContact();}}/>
        {
          showContactForm && 
          <ContactForm 
            onClose={()=>{
              this.setState({showContactForm: false})
            }}
          />
        }
      </div>
    );
  }
}


export default withRouter(Index);